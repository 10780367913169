import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import React, {useRef} from "react";
import {useKeyPressEffect} from "../helpers/hooks";
import {getValues, objectTruthyValues} from "../helpers/functions";
import Label from "../components/common/Label/Label";
import {useSelector} from "react-redux";
import {routes, statuses} from "../helpers/constants";
import {StatusIcon} from "../components/common/Common";

function routeToReduxState(state, route) {
    switch (route) {
        case routes.LICENSE: return state.licenses;
        // no default
    }
}

const warningCodes = [statuses.WARNING, statuses.MISSING, statuses.UNKNOWN, statuses.ERROR];
function selectRouteStatusCodes(state, route) {
    if (state.hasLoaded[route]) {
        const routeState = routeToReduxState(state, route);

        const statusCodes = {};
        for (const detail of getValues(routeState)) {
            if (detail.status != null && warningCodes.includes(detail.status.code)) {
                statusCodes[detail.status.code] = true;
            }
        }
        // Only show error if both error and warning exist
        if (statusCodes[statuses.ERROR]) {
            statusCodes[statuses.WARNING] = false;
            statusCodes[statuses.MISSING] = false;
        }
        
        return objectTruthyValues(statusCodes);
    }
}

function HorizontalRouteTab(props) {
    const {t} = useTranslation(['app']);

    const {
        route,
        ...attr
    } = props;

    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});
    const statusCodes = useSelector(state => selectRouteStatusCodes(state, route));

    return (
        <NavLink className="horizontal-route-tab" ref={containerRef} to={route} {...attr}>
            {(props) => (
                <>
                    {Array.isArray(statusCodes) && statusCodes.map(code => (
                        <StatusIcon key={code} status={{code}}/>
                    ))}
                    <Label value={t(`app:route.${route}`)} isEllipsis isBold={props.isActive}/>
                </>
            )}
        </NavLink>
    )
}

export default HorizontalRouteTab;