import {useCallback, useEffect, useRef, useState} from 'react';
import {getEntries, getScrollElement, isNotEmptyNorFalsy, objectTruthyValues, objEquals} from "./functions";
import {useMatch, useResolvedPath} from "react-router-dom";
import {useDispatch} from "react-redux";
import PopupModel from "../redux/models/app/PopupModel";
import genericUserIcon from "../resources/images/icons/generic-user.svg";

export function useDebounceCallback(callback, debouncePeriod) {
    const timeout = useRef();
    const wasEventDebounced = useRef(false);

    useEffect(() => {
        function clear() {
            clearTimeout(timeout.current);

            timeout.current = null;
            wasEventDebounced.current = false;
        }

        clear();
        return clear;
    }, [callback]);

    return useCallback(() => {

        function timeoutCallback() {
            if (wasEventDebounced.current) {
                callback();

                wasEventDebounced.current = false;
                timeout.current = setTimeout(timeoutCallback, debouncePeriod);
            } else {
                timeout.current = null;
            }
        }

        if (timeout.current != null) {
            wasEventDebounced.current = true;

        } else {
            callback();
            timeout.current = setTimeout(timeoutCallback, debouncePeriod);
        }
    }, [callback, debouncePeriod]);
}

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useObjEqualsMemoize(obj) {
    const ref = useRef();

    if (!objEquals(obj, ref.current)) {
        ref.current = obj;
    }
    // Will either return the old obj or the new obj
    return ref.current;
}

export function useAutoSelectId(id, ids, updateId, options={}) {
    const {
        autoSelectIfNull=true
    } = options;

    const prevIds = useRef(ids);
    // Set id if null or no longer included
    useEffect(() => {
        if (id == null) {
            if (autoSelectIfNull && ids[0] != null) {
                updateId(ids[0]);
            }

        } else if (!ids.includes(id)) {
            let index = prevIds.current.indexOf(id);
            // If index is out of bounds
            if (index !== 0 && index >= ids.length) {
                index = ids.length - 1;
            }

            if (ids[index]) {
                updateId(ids[index]);
            }
        }
    }, [id, ids, autoSelectIfNull]);

    // Update ids
    useEffect(() => {
        prevIds.current = ids;
    }, [ids]);
}

export function useVirtualRendering(options) {
    const {containerRef, itemHeight, size, heightOffset=0, windowSize=4} = options;

    const scrollContainerRef = useRef();
    const position = useRef({});
    const [, forceRender] = useState(true);

    const scrollHandler = useCallback(() => {
        if (containerRef.current == null || scrollContainerRef.current == null)
            return;

        // Calculate number of items to render
        const {top: parentTop, bottom: parentBottom} = scrollContainerRef.current.getBoundingClientRect();
        const {top} = containerRef.current.getBoundingClientRect();


        // At what index to start from
        const topDiff = ((top + heightOffset) - parentTop);
        // topDiff > 0 means first item has not been scrolled out yet
        // topDiff < 0 means first item was scrolled out
        // heightOffset is difference between containerRef.current height and when the start of first item
        const topOffset = topDiff > 0 ? 0 : Math.abs(topDiff);
        const start = topOffset === 0 ? 0 : Math.max(Math.floor(topOffset / itemHeight) - windowSize, 0);

        // How much of container is in scrollContainer
        const listTop = Math.max(top + heightOffset, parentTop);

        const listHeight = parentBottom - listTop;
        const numberOfItems = Math.ceil(listHeight / itemHeight);

        // end = 5 for 5 items
        const end = Math.min(start + numberOfItems + (windowSize * 2), size);

        if (start !== position.current.start || end !== position.current.end) {
            position.current = {start, end};
            forceRender(v => !v);
        }
    }, [itemHeight, size, windowSize, heightOffset]);

    // Update internally tracked itemHeight
    useEffect(() => {
        if (containerRef.current != null) {
            containerRef.current.style.height = `${itemHeight * size + heightOffset}px`;
        }
    }, [itemHeight, size, heightOffset]);

    // Attach scrollHandler to scrollContainer
    useEffect(() => {
        if (containerRef.current != null) {
            scrollContainerRef.current = getScrollElement(containerRef.current);
            scrollContainerRef.current.addEventListener('scroll', scrollHandler);

            scrollHandler();
            return () => scrollContainerRef.current.removeEventListener('scroll', scrollHandler);
        }
    }, [containerRef.current, scrollHandler]);

    return useCallback(renderRow => {
        const renderedRows = [];
        for (let i = position.current.start; i < position.current.end; i++) {
            renderedRows.push(renderRow(i, position.current.start * itemHeight));
        }

        return renderedRows;
    }, [position.current, itemHeight]);
}

export const initialSelectedState = {
    key: null,
    clickCount: 0,
    values: {},
    lastSelectedValue: null
};

export function useMoveUpDown(options) {
    const {selected, setSelected, setArray} = options;

    const moveUp = useCallback(() => {
        setArray(prevArray => {
            const selectedRowIndices = objectTruthyValues(selected.values).map(i => parseInt(i));
            const array = [], selectedValues = {};

            for (const selectedIndex of selectedRowIndices) {
                const index = ((selectedIndex === 0) ? prevArray.length : selectedIndex) - 1;

                array[index] = prevArray[selectedIndex];
                selectedValues[index] = true;
            }

            for (let i = 0; i < prevArray.length; i++) {
                // selected values already added, skip
                if (selected.values[i]) {
                    continue;
                }

                let index = i;
                // find new index
                while (selectedValues[index] || array[index] != null) {
                    if (index === prevArray.length - 1) {
                        index = 0;
                    } else {
                        index++;
                    }
                }

                array[index] = prevArray[i];
            }

            setSelected(prevSelected => ({
                ...prevSelected,
                values: selectedValues,
                lastSelectedValue: null
            }));
            return array;
        });
    }, [selected, setSelected, setArray]);

    const moveDown = useCallback(() => {
        setArray(prevArray => {
            const selectedRowIndices = objectTruthyValues(selected.values).map(i => parseInt(i));
            const array = [], selectedValues = {};

            for (const selectedIndex of selectedRowIndices) {
                const index = (selectedIndex === (prevArray.length - 1)) ? 0 : (selectedIndex + 1);

                array[index] = prevArray[selectedIndex];
                selectedValues[index] = true;
            }

            for (let i = 0; i < prevArray.length; i++) {
                // selected values already added, skip
                if (selected.values[i]) {
                    continue;
                }

                let index = i;
                // find new index
                while (selectedValues[index] || array[index] != null) {
                    if (index === 0) {
                        index = prevArray.length - 1;
                    } else {
                        index--;
                    }
                }

                array[index] = prevArray[i];
            }

            setSelected(prevSelected => ({
                ...prevSelected,
                values: selectedValues,
                lastSelectedValue: null
            }));
            return array;
        });
    }, [selected, setSelected, setArray]);

    return [moveUp, moveDown];
}

export function useClearSelectedEffect(options) {
    const {containerRef, setSelected, dataStructure, hidePopup, ignorePopup=true} = options;

    const prevDataStructure = usePrevious(dataStructure);
    // Clear effect whenever order of dataStructure changes
    useEffect(() => {
        if (prevDataStructure === dataStructure)
            return;

        // Since we care about order of props, JSON serialization satisfies
        if (JSON.stringify(prevDataStructure) !== JSON.stringify(dataStructure)) {
            // Objects have changed / their prop orders have changed
            setSelected(initialSelectedState);
            typeof hidePopup === 'function' && hidePopup();
        }
    }, [prevDataStructure, dataStructure, setSelected]);

    const clearSelected = useCallback(event => {
        // If event target is outside component
        if (containerRef && containerRef.current != null && !containerRef.current.contains(event.target)) {

            const popup = document.getElementById('popup');
            if (ignorePopup || popup == null || !popup.contains(event.target)) {

                setSelected(({key}) => ({
                    ...initialSelectedState,
                    key
                }));
            }
        }
    }, [containerRef, setSelected]);

    // Set eventListener to clear selectedState on outside click
    useEffect(() => {
        document.addEventListener('mousedown', clearSelected);

        return () => document.removeEventListener('mousedown', clearSelected);
    }, [clearSelected]);
}

export function useTabNavigateEffect(options) {
    const {containerRef} = options;

    const tabClick = useCallback(event => {
        const {keyCode, target} = event;

        if (keyCode === 9 && target.tagName !== 'BUTTON' &&
            containerRef.current != null && containerRef.current.contains(target)) {

            target.click();
        }

    }, [containerRef]);

    // Set eventListener for pseudo onClick when user tabs to valid input
    useEffect(() => {
        document.addEventListener('keyup', tabClick);

        return () => document.removeEventListener('keyup', tabClick);
    }, [tabClick]);
}

export function useKeyPressEffect(options) {
    const {containerRef, keyToCb} = options;

    const keyup = useCallback(event => {
        const {key, keyCode, target} = event;

        // If keyPress from container
        if (target.tagName !== 'BUTTON' && containerRef.current.contains(target)) {

            const responseCb = keyToCb[key] || keyToCb[keyCode];
            if (responseCb === 'click') {
                target.click();
            } else if (typeof responseCb === 'function') {
                responseCb();
            }
        }
    }, [containerRef, keyToCb])

    // Set eventListener
    useEffect(() => {
        document.addEventListener('keyup', keyup);

        return () => document.removeEventListener('keyup', keyup);
    }, [keyup]);
}

export function useKeySelectHandler(options) {
    const {setSelected, clickCount=1} = options;
    // Set index of selected key; requires clickCount to activate inputChange
    return useCallback(event => {
        // index turns into a string when stored in HTML
        const {dataset: {index}} = event.target;

        setSelected(prevSelected => {
            if (prevSelected.key === index) {

                if (prevSelected.clickCount === clickCount)
                    return prevSelected;

                return {
                    ...prevSelected,
                    clickCount: 1
                }
            }

            return {
                ...initialSelectedState,
                key: index
            }
        });
    }, [setSelected, clickCount]);
}

export function useValueSelectHandler(options) {
    const {setSelected, key} = options;

    return useCallback(event => {
        if ((event.target.dataset.key || event.currentTarget.dataset.key) !== key) {
            return;
        }

        const index = parseInt(event.target.dataset.index || event.currentTarget.dataset.index);
        const keyPressed = {
            ctrl: event.ctrlKey,
            shft: event.shiftKey
        }

        setSelected(prevSelected => {
            // Maintain previous selection if CTRL/SHFT key pressed
            let selectedIndexSet = {};
            if (keyPressed.ctrl || keyPressed.shft) {
                selectedIndexSet = {...prevSelected.values};
            }

            // False IFF CTRL key pressed and was previously selected
            selectedIndexSet[index] = !keyPressed.ctrl || !selectedIndexSet[index];

            // If range click with SHFT
            if (keyPressed.shft && prevSelected.lastSelectedValue != null) {
                // Get index start/end range
                let start, end;
                if (index > prevSelected.lastSelectedValue) {
                    start = prevSelected.lastSelectedValue;
                    end = index;
                } else {
                    start = index;
                    end = prevSelected.lastSelectedValue;
                }

                for (let i = start; i < end; i++) {
                    selectedIndexSet[i] = true;
                }
            }

            return {
                ...prevSelected,
                values: selectedIndexSet,
                lastSelectedValue: index
            }
        });
    }, [setSelected, key]);
}

const userIconMap = {};

export function useUserIcon(user) {
    const icon = userIconMap[user] || genericUserIcon;
    const [userIcon, ] = useState(icon);

    // // Query for userIcon only if undefined
    // useEffect(() => {
    //     if (user != null && icon === undefined) {
    //
    //         // To prevent multiple calls (for case multiple components request icon for same user)
    //         userIconMap[user] = null;
    //
    //         let isCancelled = false;
    //         axiosProxy.get(`${axiosProxy.baseUrl}/users/icon`, {responseType: 'blob'})
    //             .then(res => {
    //                 const iconBlob = URL.createObjectURL(res.data);
    //
    //                 userIconMap[user] = iconBlob;
    //                 if (!isCancelled) {
    //                     setUserIcon(iconBlob);
    //                 }
    //             })
    //             // Ignore errors
    //             .catch(() => {
    //                 if (!isCancelled) {
    //                     setUserIcon(null);
    //                 }
    //             });
    //
    //         return () => isCancelled = true;
    //     }
    // }, [user, icon == null]);
    //
    //
    // // For case userIcon updated elsewhere
    // useEffect(() => {
    //     setUserIcon(icon);
    // }, [icon]);

    return userIcon;
}

export function useCloseHandler(options) {
    const {popupKey, values, blacklist=[], close} = options;
    const dispatch = useDispatch();

    const warnOnClose = useCallback(function() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupKey
            },
            buttons: [{
                titleKey: 'common:option.discard',
                onClick: close
            }]
        }));
    }, [dispatch, popupKey, close]);

    const notSafe = getEntries(values)
        .filter(([key, val]) => !blacklist.includes(key) && !['function', 'boolean'].includes(typeof val))
        .some(([ignore, val]) => isNotEmptyNorFalsy(val));

    if (notSafe)
        return warnOnClose;
    return close;
}

export function useRouteMatches(route, end=true) {
    // Resolving route to current path
    const resolved = useResolvedPath(route);
    const match = useMatch({path: resolved.pathname, end});
    // match != null IFF resolvedPath matches current browser path
    return match != null;
}
