import {all} from 'redux-saga/effects';
import {contextSpawn} from "../helpers/sagas";
import AppSaga from "./sagas/app/AppSaga";
import CurrentUserSaga from "./sagas/app/CurrentUserSaga";
import LicensesSaga from "./sagas/home/LicensesSaga";
import ApiKeysSaga from "./sagas/home/ApiKeysSaga";


function* rootSaga() {
    yield all([
        contextSpawn(AppSaga, 'run'),
        contextSpawn(CurrentUserSaga, 'run'),

        contextSpawn(LicensesSaga, 'run'),
        contextSpawn(LicensesSaga, 'takePollDetails'),

        contextSpawn(ApiKeysSaga, 'run'),
        contextSpawn(ApiKeysSaga, 'takePollDetails')
    ]);
}

export default rootSaga;