
export const QUERY_INTERVAL = 30000;
export const SLOW_QUERY_INTERVAL = 60000;

export const PASSWORD_PLACEHOLDER = Array(24).fill("*").join('');
export const byteUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

export const details = {
    LICENSE: '/license',
    API_KEYS: '/apiKeys'
};

export const routes = {
    HOME: '/',
    LICENSE: '/license',
    API_KEYS: '/apiKeys',
    RESOURCES: '/resources'
};

export const statuses = {
    OK: 'OK',
    INFO: 'INFO',
    UNKNOWN: 'UNKNOWN',
    MISSING: 'MISSING',
    WARNING: 'WARNING',
    ERROR: 'ERROR'
};

export const languages = {
    BROWSER_DEFAULT: null,
    AR_AE: 'ar-AE',
    DA_DK: 'da-DK',
    DE_DE: 'de-DE',
    EN_US: 'en-US',
    ES_419: 'es-419',
    FR_CA: 'fr-CA',
    HE_IL: 'he-IL',
    JA_JP: 'ja-JP',
    KO_KR: 'ko-KR',
    NL_NL: 'nl-NL',
    PT_BR: 'pt-BR',
    ZH_CN: 'zh-CN'
};