import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {PageHeader} from "../../common/PanelPage/TabbedPage";
import ApiKeysModel from "../../../redux/models/home/ApiKeysModel";
import Label from "../../common/Label/Label";
import {TableLabelCell, TableLabelHeader} from "../../common/Table/Table";
import {buildClassName, getLocaleDateFromUTC, getValues} from "../../../helpers/functions";
import {AddButtonHeader} from "../../common/Button/Button";
import {Outlet} from "react-router-dom";
import {useRouteMatches} from "../../../helpers/hooks";

function ApiKeysPage() {
    const {t} = useTranslation(['apiKeys']);
    const dispatch = useDispatch();

    const apiKeys = getValues(useSelector(state => state.apiKeys));

    useEffect(() => {
        dispatch(ApiKeysModel.componentActionCreators.setDisplayActive());
        return () => dispatch(ApiKeysModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);

    const showForm = useCallback(function () {
        dispatch(ApiKeysModel.actionCreators.showForm());
    }, [dispatch]);

    return (
        <>
            <PageHeader title={t('apiKeys:label.name')} description={t('apiKeys:message.description')}/>

            <AddButtonHeader title={t('aria:button.apiKeysAdd')} label={t('apiKeys:label.name_singular')}
                canModify onClick={showForm} style={{marginTop: '2rem'}}/>

            {apiKeys.length > 0 &&
                <div className="display-table">
                    <div className="table-header-group">
                        <TableLabelHeader label={t('apiKeys:label._name')}/>

                        <TableLabelHeader label={t('apiKeys:label.id')}/>

                        <TableLabelHeader label={t('apiKeys:label.createdDay')}/>

                        <TableLabelHeader label={t('apiKeys:label.validityDays')}/>

                        <TableLabelHeader label={t('apiKeys:label.lastUsed')}/>
                    </div>

                    <div className="table-row-group">
                        {apiKeys.map(apiKey => (
                            <ApiKeyRow key={apiKey.id} apiKey={apiKey}/>
                        ))}
                    </div>
                </div>
            }

            <Outlet/>
        </>
    )
}

function ApiKeyRow(props) {
    const dispatch = useDispatch();

    const {
        apiKey
    } = props;

    // Resolving @id to path
    const isActive = useRouteMatches(apiKey.id);
    const className = buildClassName(
        'table-row',
        isActive && 'is-active'
    );

    const showView = useCallback(function () {
        dispatch(ApiKeysModel.actionCreators.showView(apiKey.id));
    }, [dispatch, apiKey.id]);

    return (
        <div className={className} onClick={showView}>
            <div className="table-cell">
                <Label value={apiKey.name} isBold/>
                {apiKey.description &&
                    <Label value={apiKey.description}/>
                }
            </div>

            <TableLabelCell label={apiKey.id} isWordWrap/>

            <TableLabelCell label={getLocaleDateFromUTC(apiKey.createdDay)}/>

            <TableLabelCell label={apiKey.validityDays}/>

            <TableLabelCell label={apiKey.lastUsedDay ? getLocaleDateFromUTC(apiKey.lastUsedDay) : 'N/A'}/>
        </div>
    )
}

export default ApiKeysPage;