import React from 'react';
import './Popups.css';
import CustomModal from '../CustomModal/CustomModal';
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";
import {getPluralTranslations, isNotEmptyNorFalsy, switchcase} from "../../../helpers/functions";
import {useDispatch, useSelector} from "react-redux";
import PopupModel from "../../../redux/models/app/PopupModel";
import disconnectedIcon from "../../../resources/images/icons/disconnected.svg";
import successIcon from "../../../resources/images/icons/success.svg";
import errorIcon from "../../../resources/images/icons/error.svg";
import warningIcon from "../../../resources/images/icons/warning.svg";
import infoIcon from "../../../resources/images/icons/info.svg";
import Label from "../Label/Label";

export function Popup(props) {
    const {t} = useTranslation(['popupInfo', 'common']);
    const dispatch = useDispatch();

    const {
        title,
        info,
        icon,
        content,
        hide,
        hideButtons,
        buttons=[],
        cancelButton={},
        areButtonsDisabled,
        ...attr
    } = props;

    // Popup assumed active if info or content exists
    if (info == null && content == null)
        return null;

    function onCancel() {
        if (cancelButton.onClick != null) {
            cancelButton.onClick(dispatch);
        }
        hide();
    }

    let message, translationTitle;
    if (info != null) {
        const values = {...info.values, ...info.valueKeys, returnObjects: true};
        const translatedInfo = getPluralTranslations(t, `popupInfo:${info.key || 'template'}`, values);

        translationTitle = translatedInfo.title;
        message = translatedInfo.message || info.key;
    }

    // If no message, title becomes message with default title
    if (!isNotEmptyNorFalsy(message)) {
        message = translationTitle
        translationTitle = title;
    }

    const cancelTitle = (cancelButton.titleKey && t(cancelButton.titleKey)) || cancelButton.title || t('common:option.cancel');
    const disabled = attr.isDisabled ? ' is-disabled' : '';

    return (
        <CustomModal isActive id="popup" onCancel={onCancel} {...attr}
            header={
                <div className={'popup-header' + disabled}>
                    <h1 className="subtitle is-6 is-bold">
                        {translationTitle || title}
                    </h1>
                </div>
            }
            body={
                <div className={'popup-body' + disabled}>
                    {icon &&
                    <span className="icon is-large">
                        {icon}
                    </span>
                    }

                    <div className="popup-message">
                        <label className="label is-wordwrap">
                            {mapLines(message)}
                        </label>

                        {content}
                    </div>
                </div>
            }
            footer={
                <div className={'popup-footer button-group' + disabled}>
                    {!hideButtons &&
                    <>
                        <Button label={cancelTitle} onClick={onCancel}
                            isDisabled={attr.isDisabled || cancelButton.isDisabled}/>

                        {buttons.map((bouton, index) => {
                            function onClick(event) {
                                if (Array.isArray(bouton.onClick)) {
                                    bouton.onClick.forEach(func => func(dispatch, event));
                                } else if (typeof bouton.onClick === 'function') {
                                    bouton.onClick(dispatch, event);
                                }
                                if (!bouton.ignoreHide) {
                                    hide();
                                }
                            }

                            return (
                                <Button key={index} name={bouton.name} value={bouton.value} onClick={onClick}
                                    label={bouton.title || t(bouton.titleKey)} isDisabled={attr.isDisabled || bouton.isDisabled || areButtonsDisabled}
                                />
                            )
                        })}
                    </>
                    }
                </div>
            }
        />
    )
}

function mapLines(lines) {
    if (!Array.isArray(lines)) {
        return lines;
    }

    return lines.map((line, index) => {
        if (typeof line === 'string') {
            return (
                <span key={index}>
                    {line} <br/>
                </span>
            )
        }

        return (
            <span key={index} style={line.style}>
                {line.value} <br/>
            </span>
        )
    });
}

export function SimplePopup(props) {
    const {t} = useTranslation(['popupInfo']);
    const dispatch = useDispatch();

    const popupEntry = useSelector(state => [...state.popupDetails].pop());
    if (popupEntry == null)
        return null;

    const [id, popup] = popupEntry;
    const icon = switchcase({
        'info': infoIcon,
        'success': successIcon,
        'warning': warningIcon,
        'error': errorIcon
    })(popup.icon)(popup.icon);

    return (
        <Popup {...popup} {...props} title={t(`popupInfo:label.${popup.icon}`)} icon={icon && <img src={icon} alt={popup.icon}/>}
            hide={() => dispatch(PopupModel.actionCreators.hide(id))}
        />
    )
}

export function DisconnectPopup(props) {
    const {isActive} = props;
    const {t} = useTranslation(['popupInfo']);

    const {title, message} = t(`popupInfo:disconnected`, {returnObjects: true});

    return (
        <CustomModal {...{isActive, isRigid: true, showX: false}}>
            <div className="disconnect-popup">

                <img src={disconnectedIcon} alt={'common:label.disconnected'}/>
                <h1 className="title is-5">
                    {title}
                </h1>
                {mapIntoLines(message)}
            </div>
        </CustomModal>
    );
}

//For Array of strings or stand-alone strings; behavior neglected for other use cases
const mapIntoLines = obj => {
    return (obj &&
        <Label isWordWrap>
            {obj.constructor === Array ?
                obj.map(e =>
                    <span key={e}>
                        {e} <br/>
                    </span>)
                :
                obj
            }
        </Label>
    );
}
