import React from 'react';
import './SearchBar.css';
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";
import searchIcon from "../../../resources/images/icons/search.svg";
import closeIcon from "../../../resources/images/icons/close.svg";

function SearchBar(props) {
    const {searchBarRef, name='searchText', value, onChange, isDisabled, ...attr} = props;
    const {t} = useTranslation(['aria', 'common']);

    function clearInput() {
        const event = {
            target: {name, value: ''}
        };
        onChange(event);
    }

    const hasValue = value && value.length > 0;
    const icon = hasValue ? closeIcon : searchIcon;
    const title = t(`aria:button.${ hasValue ? 'clear' : 'search'}`);

    return (
        <div className={'search-bar' + (isDisabled ? ' is-disabled' : '')} ref={searchBarRef} {...attr}>
            <input className="input is-small" name={name} type="search" autoComplete="off" placeholder={attr.placeholder || t('aria:input.search')}
                value={value} onChange={onChange} disabled={isDisabled}
            />

            <Button title={title} isImg onClick={clearInput} isDisabled={isDisabled}>
                <span className="icon">
                    <img src={icon} alt={title}/>
                </span>
            </Button>
        </div>
    );
}

export default SearchBar;