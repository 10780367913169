import React, {useCallback, useState} from "react";
import {PageHeader} from "../../common/PanelPage/TabbedPage";
import {useTranslation} from "react-i18next";
import Label, {LinkLabel} from "../../common/Label/Label";
import {useDispatch, useSelector} from "react-redux";
import PopupModel from "../../../redux/models/app/PopupModel";
import CurrentUserModel from "../../../redux/models/app/CurrentUserModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";

function ResourcesPage() {
    const {t} = useTranslation(['app', 'resources']);
    const dispatch = useDispatch();

    const {isDisabled} = useSelector(state => state.componentState.resourcesPage);

    const onDeleteAccount = useCallback(() => {
        dispatch(PopupModel.actionCreators.showWarning({
            id: 'closeUserAccount',
            info: {
                key: 'closeUserAccount'
            },
            buttons: [{
                titleKey: 'resources:option.closeAccount',
                onClick: dispatch => dispatch(CurrentUserModel.actionCreators.closeUserAccount())
            }],
            content: (
                <CloseAccountPopupContent/>
            ),
            areButtonsDisabled: true
        }));
    }, [dispatch]);

    return (
        <>
            <PageHeader title={t('resources:label.name')}/>

            <div style={{marginTop: '2rem'}}>
                <Label value={t('resources:label.help')} isHeading isDisabled={isDisabled}/>
                <LinkLabel href={'https://community.rampiva.com'} label={t('resources:label.rampivaCommunity')}
                    isDisabled={isDisabled}/>
            </div>

            <div className="display-item">
                <Label value={t('resources:label.rampivaProducts')} isHeading isDisabled={isDisabled}/>
                <LinkLabel href={'https://rampiva.com/editions/'} label={t('resources:label.rampivaAutomateEditions')}/>      
            </div>

            <div className="display-item">
                <Label value={t('resources:label.documentation')} isHeading isDisabled={isDisabled}/>
                <LinkLabel href={'https://kb.rampiva.com'} label={t('resources:label.knowledgeBase')} isDisabled={isDisabled}/>
            </div>

            <div className="display-item">
                <Label value={t('resources:label.account')} isHeading isDisabled={isDisabled}/>
                <Label className={'link-label'} onClick={isDisabled ? null : onDeleteAccount}
                    isDisabled={isDisabled}
                >
                    {t('resources:option.closeAccount')}
                </Label>
            </div>
        </>
    );
}

function CloseAccountPopupContent() {
    const {t} = useTranslation(['registration'])
    const dispatch = useDispatch();

    const [value, setValue] = useState('');
    const closeAccountText = t('resources:option.closeAccount');

    function onInputChange(event) {
        setValue(event.target.value);

        dispatch(PopupModel.actionCreators.update('closeUserAccount', {
            areButtonsDisabled: event.target.value !== closeAccountText
        }));
    }

    return (
        <HTMLTextInput value={value} onChange={onInputChange}
            isInvalid={value !== closeAccountText} isRequired/>
    )
}

export default ResourcesPage;