import {getEntries, getKeys, isNotEmptyNorFalsy} from "./functions";

function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}

export const inputPasteHandler = createInputPasteHandler();
function createInputPasteHandler() {
    return function onInputPaste(event) {
        const pastedText = event.clipboardData.getData('text');

        // Assuming input values are colon separated
        const idToValues = pastedText.split('&').reduce((acc, curr) => {
            const [key, val] = curr.split('=');
            acc[key] = val;
            return acc;
        }, {});

        let defaultBehavior = true;
        // Resume default behavior if pastedText doesn't follow required pattern; else preventDefault
        if (!isNotEmptyNorFalsy(idToValues)) {
            return;
        }

        for (const [key, val] of getEntries(idToValues)) {
            const input = document.getElementById(key);
            if (input) {
                defaultBehavior = false;
                setNativeValue(input, val);
                input.dispatchEvent(new Event('input', {bubbles: true}));
            }
        }

        if (!defaultBehavior) {
            event.preventDefault();
        }
    }
}

// InputHandler with safe password handling
export function createInputHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, value, type} = event.target;

        const updates = {};
        // Using same inputHandler for password field for isEnabled logic
        // Do not track passwords
        if (type !== 'password')
            updates[name] = value;

        handler(updates, event);
    }
}

export function createValueHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, value} = event.currentTarget.dataset;
        const updates = {
            [name]: value
        };

        handler(updates);
    }
}

export function getHandler(options) {
    if (options.handler != null)
        return options.handler;

    return createStateHandler(options);
}

export function createStateHandler(options) {
    const {updateState, passwordOptions, shouldEnable, isEnabled='isAddEnabled'} = options;

    return function(updates) {

        const passwords = {};
        if (passwordOptions != null) {
            for (const name of getKeys(passwordOptions)) {
                const {ref, resetNames = []} = passwordOptions[name];
                // clear password if a resetName is being updated
                if (ref != null && ref.current != null) {
                    if (resetNames.some(name => updates[name] != null)) {
                        ref.current.value = '';
                    }
                    // value for shouldEnable function
                    passwords[name] = ref.current.value;
                }
            }
        }

        updateState(prevState => {
            if (typeof shouldEnable === 'function' && typeof isEnabled === 'string') {
                updates[isEnabled] = shouldEnable({
                    ...prevState,
                    ...updates,
                    ...passwords
                })
            }

            return {
                ...prevState,
                ...updates
            }
        });
    }
}

export function createNestedUpdateHandler(handler, ...nesting) {
    return function(updates) {
        handler(prevState => {
            const state = {
                ...prevState
            };

            let current = state;
            for (let i = 0; i < nesting.length; i++) {
                current = current[nesting[i]] = {
                    ...current[nesting[i]]
                }
            }

            let update = updates;
            if (typeof updates === 'function') {
                update = updates(current);
            }

            Object.assign(current, update);
            return state;
        });
    }
}