import {all, call, delay, fork, put, race, take, takeLatest} from 'redux-saga/effects';
import PopupModel from "../../models/app/PopupModel";
import {getKeys, getValues, stringToBool} from "../../../helpers/functions";
import {getLocalStorageSubsetWithPrefix} from "../../../helpers/localStorage";
import CurrentUserModel from "../../models/app/CurrentUserModel";
import {buffers, eventChannel} from "redux-saga";
import {throttle} from "../../../helpers/sagas";


// DEBOUNCE_TIME must be smaller than idleTime because resetAction is sent AT MOST ONCE every DEBOUNCE_TIME
// resetAction sent -> Must wait DEBOUNCE_TIME before another resetAction can be sent
const COUNTDOWN = 30;
const DEFAULT_TIMEOUT = 300; // 5 minutes
const DEBOUNCE_TIME = 5000; // 5 seconds

const WINDOW_FOCUS_TIMEOUT_REACHED = 'WINDOW_FOCUS_TIMEOUT_REACHED';
const IDLE_HANDLER_RESET_BOOL = 'idleHandlerResetBool';

export const activityEvents = ['mousedown', 'keydown', 'touchstart', 'scroll'];

export function* idleHandler(timeout=DEFAULT_TIMEOUT) {
    // Dispatch reset action for current and other window/tabs
    function* dispatchReset() {
        yield put(CurrentUserModel.actionCreators.resetIdleTimer());
        // Using localStorage to reset counter between tabs/windows
        const resetBool = stringToBool(localStorage.getItem(IDLE_HANDLER_RESET_BOOL));
        localStorage.setItem(IDLE_HANDLER_RESET_BOOL, !resetBool);
    }

    // Subscribing to activityEvents
    function idleResetSubscriber(emitter) {
        activityEvents.forEach(e => document.body.addEventListener(e, emitter));
        return () => activityEvents.forEach(e => document.body.removeEventListener(e, emitter));
    }
    // Throttle dispatching resets from activityEvent subscriber
    const idleResetChannel = eventChannel(idleResetSubscriber, buffers.sliding(1));
    yield fork(throttle(DEBOUNCE_TIME, idleResetChannel, dispatchReset));


    // Rest on inter-tab reset
    const storageResetChannel = eventChannel(emitter => {
        window.addEventListener('storage', emitter);
        return () => window.removeEventListener('storage', emitter);
    });
    yield takeLatest(storageResetChannel, function* (event) {
        if (event.key === 'idleHandlerResetBool') {
            yield put(CurrentUserModel.actionCreators.resetIdleTimer());
        }
    });

    // Check if timeout reached when returning to window/tab
    const windowFocusChannel = eventChannel(emitter => {
        window.addEventListener('focus', emitter);
        return () => window.removeEventListener('focus', emitter);
    });
    yield takeLatest(windowFocusChannel, function* () {
        if (Date.now() - lastResetTime > timeout * 1000) {
            const anyTabsPaused = getValues(getLocalStorageSubsetWithPrefix(pauseKeyPrefix))
                .some(e => e);
            if (!anyTabsPaused) {
                yield put({type: WINDOW_FOCUS_TIMEOUT_REACHED});
            }
        }
    });

    try {
        // Wait for idleWatcher to reach timeout OR returning to window/tab after timeout reached
        yield race([
            take(WINDOW_FOCUS_TIMEOUT_REACHED),
            call(idleWatcher, timeout),
        ]);
    } finally {
        idleResetChannel.close();
        storageResetChannel.close();
        windowFocusChannel.close();
    }
}

let lastResetTime;
// Restart @idleTimer on reset action
function* idleWatcher(timeout) {
    let resetAction;
    do {
        lastResetTime = Date.now();
        [resetAction] = yield race([
            take(CurrentUserModel.actions.RESET_IDLE_TIMER),
            call(idleTimer, timeout)
        ]);
    } while (resetAction);
}

function* idleTimer(timeout) {
    // Delay time before showing idle warning popup (min 5 seconds)
    const delayTime = Math.max(5, timeout - COUNTDOWN);
    let anyTabsPaused = false;
    do {
        yield delay(delayTime * 1000);
        // Effectively delay while idleHandler is paused
        anyTabsPaused = getValues(getLocalStorageSubsetWithPrefix(pauseKeyPrefix))
            .some(e => e);
    } while (anyTabsPaused);

    // Lower COUNTDOWN for timeouts < 30 & > 5 (no popup for < 5)
    let effectiveCountdown = Math.min(timeout - 5, COUNTDOWN);
    try {
        for (; effectiveCountdown > 0; effectiveCountdown--) {
            yield all([
                put(PopupModel.actionCreators.showWarning({
                    id: 'idleHandlerWarningTimerPopup',
                    info: {
                        key: 'idleWarning',
                        values: {
                            timer: effectiveCountdown
                        }
                    }
                })),
                delay(1000)
            ]);
        }
    } finally {
        yield put(PopupModel.actionCreators.hide('idleHandlerWarningTimerPopup'))
    }
    // Timeout reached; logout user
    yield put(CurrentUserModel.actionCreators.logoutUser());
}

export function setIsIdleHandlerPaused(isPaused) {
    localStorage.setItem(pauseId, isPaused);
}

export function setIsIdleHandlerPausedId(id, isPaused) {
    localStorage.setItem(`${pauseId}-${id}`, isPaused);
}

const pauseKeyPrefix = 'idleHandlerPauseBool';
// Create a tab session id to be used for tracking tab-paused
let pauseId;
{
    const randomArr = new Uint32Array(1);
    do {
        window.crypto.getRandomValues(randomArr);
        pauseId = `${pauseKeyPrefix}_${randomArr[0]}`;

    } while (localStorage.getItem(pauseId) != null);

    // Add tabId to storage
    localStorage.setItem(pauseId, false)
    window.addEventListener('beforeunload', clearTabKeysOnUnload);
}

// Remove unique tabId + pauseKey
function clearTabKeysOnUnload() {
    const keys = getKeys(getLocalStorageSubsetWithPrefix(pauseId));
    for (const key of keys) {
        localStorage.removeItem(key);
    }
    window.removeEventListener('beforeunload', clearTabKeysOnUnload);
}

export default idleHandler;