import React from "react";
import "./Table.css";
import Label from "../Label/Label";
import {buildClassName} from "../../../helpers/functions";

export function TableLabelHeader(props) {
    const {
        label,
        alignRight,
        isBold = true,
        className,
        isDisabled,
        ...attr
    } = props;

    const _className = buildClassName(
        'table-header',
        alignRight && 'align-right',
        className
    );

    return (
        <div className={_className} {...attr}>
            {label &&
                <Label value={label} isEllipsis isBold={isBold} isDisabled={isDisabled}/>
            }
        </div>
    )
}

export function TableLabelCell(props) {
    const {
        label,
        alignRight,
        className,
        style,
        ...attr
    } = props

    const _className = buildClassName(
        'table-cell',
        alignRight && 'align-right',
        className
    );

    return (
        <div className={_className} style={style}>
            {label != null &&
                <Label value={label} isEllipsis {...attr}/>
            }
        </div>
    )
}

export function FormElementRow(props) {
    const {label, htmlFor, element, labelStyle, isDisabled} = props;

    return (
        <div className="table-row">
            <div className="table-cell" style={labelStyle}>
                <Label htmlFor={htmlFor} value={`${label}:`}
                    isDisabled={isDisabled}/>
            </div>

            <div className="table-cell">
                {element}
            </div>
        </div>
    )
}

export function SettingsRowValue(props) {
    const {
        htmlFor,
        label,
        value,
        isItalic,
        isBold,
        isWordWrap=true,
        isWordBreakAll,
        isTight,
        isDisabled,
        labelStyle,
        showIfNull,
        valueIsItalic,
        valueIsBold
    } = props;

    if (value == null && !showIfNull)
        return null;

    const tight = isTight ? ' is-tight' : '';

    return (
        <div className="settings-row">
            <div className={'settings-label-cell' + tight} style={labelStyle}>
                {label &&
                    <Label htmlFor={htmlFor} value={`${label}:`} isItalic={isItalic} isBold={isBold}
                        isDisabled={isDisabled}/>
                }
            </div>
            <div className="settings-value-cell">
                {(typeof value === 'string' || typeof value === 'number') ?
                    <Label value={value} isItalic={valueIsItalic} isBold={valueIsBold}
                        isWordWrap={isWordWrap} isWordBreakAll={isWordBreakAll} isDisabled={isDisabled}/>
                    :
                    value == null ? '-' : value
                }
            </div>
        </div>
    );
}

export function SettingsRowSeparator(props) {
    return (
        <div className="settings-row">
            <div className={`settings-row-separator is-${props.size}`}/>
        </div>
    );
}
