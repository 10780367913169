import {useTranslation} from "react-i18next";
import "./RegistrationPage.css";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useRef} from "react";
import {createInputHandler} from "../../../helpers/handlers";
import CurrentUserModel from "../../../redux/models/app/CurrentUserModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {Button} from "../../common/Button/Button";
import Label from "../../common/Label/Label";
import ReCaptchaV2 from "react-google-recaptcha";

const captchaSiteKey = '6LdbDBIeAAAAABhcTOGPzyEGAd53PPGheyDvfnzJ'

function RegistrationPage() {
    const {t} = useTranslation(['registration']);
    const dispatch = useDispatch();

    const {
        organizationName,
        captchaValue,
        isDisabled
    } = useSelector(state => state.componentState.registrationPage);

    const captchaRef = useRef();
    // Reset captcha when value null
    useEffect(() => {
        if (captchaValue == null && captchaRef.current != null) {
            captchaRef.current.reset();
        }
    }, [captchaValue]);

    const setState = useCallback(updates => {
        dispatch(CurrentUserModel.componentActionCreators.updateRegistrationPage(updates));
    }, [dispatch]);

    const inputHandler = createInputHandler({
        updateState: setState
    });

    function onCaptchaChange(value) {
        setState({
            captchaValue: value
        });
    }

    function onSubmit(event) {
        event.preventDefault();
        dispatch(CurrentUserModel.actionCreators.initializeUser());
    }


    const isSubmitEnabled = !!(organizationName && captchaValue);
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="centered-page">
            <div className="registration-box">
                <div className="registration-header">
                    <h1 className={'subtitle is-bold' + disabled}>
                        {t('registration:label.welcome')}
                    </h1>
                </div>

                <form className="registration-form">
                    <Label value={t('registration:message.thankYouForJoining')} isDisabled={isDisabled}/>

                    <div className="display-item">
                        <HTMLTextInput label={t('registration:label.organizationName')} name={'organizationName'} value={organizationName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired isInvalid={organizationName.length < 3}/>
                    </div>

                    <div className="display-item">
                        <Label isDisabled={isDisabled}>
                            {t('registration:message.registerAcceptance_1')} <a href="https://rampiva.com/legal/portal-terms" target="_blank" rel="noopener noreferrer">{t('registration:message.registerAcceptance_2')}</a> {t('registration:message.registerAcceptance_3')} <a href="https://rampiva.com/legal/eula" target="_blank" rel="noopener noreferrer">{t('registration:message.registerAcceptance_4')}</a>.
                        </Label>
                    </div>

                    <div className={'login-captcha display-item' + disabled}>
                        <ReCaptchaV2 ref={captchaRef} sitekey={captchaSiteKey} onChange={onCaptchaChange}/>
                    </div>

                    <div className="button-group">
                        <Button label={t('registration:option.register')} type={'submit'}
                            onClick={onSubmit} isDisabled={!isSubmitEnabled || isDisabled}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegistrationPage;