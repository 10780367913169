import {all, call, cancel, fork, put, select, take, takeLeading} from "redux-saga/effects";
import ComponentStateModel from "../models/ComponentStateModel";
import AppModel from "../models/app/AppModel";
import {keepAlive} from "../../helpers/sagas";

export class SagaRunnable {

    static activationComponent = '';

    constructor() {
        throw new Error('Attempted abstract class implementation. Sagas are abstract.');
    }

    static history;
    static* run() {
        yield all([
            // SAGA ACTIVATION LISTENER
            takeLeading(ComponentStateModel.actions.SET_COMPONENT_ACTIVE, function* (action) {
                const {component} = action.payload;

                if (component === this.activationComponent) {
                    if (this.tasks) {
                        yield cancel(this.tasks);
                    }

                    this.tasks = yield fork(keepAlive, function* () {
                        yield all(this.buildActivationEffects());
                    }.bind(this));
                }
            }.bind(this)),
            // SAGA DEACTIVATION LISTENER
            takeLeading(ComponentStateModel.actions.SET_COMPONENT_INACTIVE, function* (action) {
                const {component} = action.payload;

                if (component === this.activationComponent) {
                    if (this.tasks) {
                        yield cancel(this.tasks);
                    }
                    yield all(this.buildDeactivationEffects());
                }
            }.bind(this))
        ]);
    }

    static buildActivationEffects() {
        return [];
    }

    static buildDeactivationEffects() {
        return [];
    }

    static authenticatedEffects(...effects) {
        return call(function* () {
            const currentUser = yield select(state => state.currentUser);
            if (currentUser.isAuthenticated) {
                yield all(effects);
            }
        });
    }

    static* tryCatchReattemptWrapper(saga, ...args) {
        const errorMillis = [];
        let reattempt;
        do {
            try {
                reattempt = false;
                return yield call(saga, ...args);
            } catch (error) {
                yield put(AppModel.actionCreators.handleError(error));
                // If service disconnected, wait for reconnect
                if (error.request && !error.response) {
                    yield take(AppModel.actions.SET_CONNECTED);
                    reattempt = true;
                }

                errorMillis.push(Date.now());
                // If 5 errors occurred within 1s, exit
                if (errorMillis[4] - errorMillis[0] < 1000) {
                    console.log('Saga has been stopped due to repeat error. Please refresh your browser.')
                    break;
                }
                if (errorMillis.length > 5) {
                    errorMillis.length = 0;
                }
            }
        } while (reattempt);
    }

    static* tryCatchWrapper(saga, ...args) {
        try {
            return yield call(saga, ...args);
        } catch (error) {
            yield put(AppModel.actionCreators.handleError(error));
        }
    }

    static* disableWrapper(updateState, saga, ...args) {
        if (typeof updateState !== 'function') {
            return yield call(saga, ...args);
        }
        try {
            yield all([
                put(updateState({isDisabled: true})),
                call(saga, ...args)
            ]);
        } finally {
            yield put(updateState({isDisabled: false}));
        }
    }
}

export default SagaRunnable;