import React from "react";
import "../common/PanelPage/TabbedPage.css";
import {Outlet} from "react-router-dom";
import {routes} from "../../helpers/constants";
import {getValues} from "../../helpers/functions";
import HorizontalRouteTab from "../../router/HorizontalRouteTab";
import {useSelector} from "react-redux";
import {selectCanViewRoute} from "../app/App";

function HomePage() {
    const canViewRoute = useSelector(selectCanViewRoute);

    return (
        <div className="tabbed-page">
            <div className="tabbed-page-tabs">

                <div className="tabs-list-header"/>
                <div className="tabs-list">
                    {getValues(routes)
                        .filter(route => canViewRoute[route])
                        .map(route =>
                            <HorizontalRouteTab key={route} route={route}/>
                    )}
                </div>
            </div>

            <div className="tabbed-page-display">
                <Outlet />
            </div>
        </div>
    )
}

export default HomePage;