import {cancelled} from "redux-saga/effects";
import {checkNestedExists, generateUUID4} from "../helpers/functions";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./AxiosWorker.js";

export const devUrl = 'https://dev.api.rampiva.services';
class AxiosProxy {

    etags = {};
    shouldUpdate(key, response) {
        let etag = null;
        if (checkNestedExists(response, 'headers', 'etag')) {
            etag = response.headers.etag;
        } else if (Array.isArray(response)) {
            etag = response.map(res => res.headers.etag).filter(etag => etag).join('')
        } else if (typeof response === 'string') {
            etag = response;
        }

        if (!this.etags[key] || !etag || this.etags[key] !== etag) {
            this.etags[key] = etag;
            return true;
        }
    }

    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL || devUrl;

        this.idToRequest = {};
        this.worker = new Worker();
        this.worker.postMessage(['initialize', this.baseUrl]);
        this.worker.onmessage = this.handlerWorkerResponse.bind(this);
    }

    get(...args) {
        return this.postWorkerRequest('get', ...args);
    }

    post(...args) {
        return this.postWorkerRequest('post', ...args);
    }

    put(...args) {
        return this.postWorkerRequest('put', ...args);
    }

    del(...args) {
        return this.postWorkerRequest('del', ...args);
    }

    setApiKey(key) {
        this.worker.postMessage(['setApiKey', key]);
    }

    clearAxiosToken() {
        this.worker.postMessage(['clearAxiosToken']);
    }

    *cancelWrapper(method, ...args) {
        const id = generateUUID4();
        try {
            return yield this.postWorkerRequestWithId(id, method, ...args);
        } finally {
            if (yield cancelled()) {
                this.worker.postMessage(['cancel', id]);
            }
        }
    }

    postWorkerRequest(method, ...args) {
        const id = generateUUID4();
        return this.postWorkerRequestWithId(id, method, ...args);
    }

    postWorkerRequestWithId(id, method, ...args) {
        return new Promise(async (resolve, reject) => {
            this.worker.postMessage(['request', id, method, ...args]);
            this.idToRequest[id] = {resolve, reject};
        });
    }

    handlerWorkerResponse(event) {
        const [action, ...args] = event.data;
        switch (action) {
            case 'resolve':
            case 'reject': {
                const [id, ...others] = args;

                // Resolve or reject promise
                const request = this.idToRequest[id];
                if (request != null && typeof request[action] === 'function') {
                    request[action](...others);
                }

                delete this.idToRequest[id];
                break;
            }
        }
    }
}

const axiosProxy = new AxiosProxy();
export default axiosProxy;