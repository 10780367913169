import ComponentStateModel from "../ComponentStateModel";
import ReduxModel from "../ReduxModel";

class AppModel extends ReduxModel {

    static nom = 'AppModel';
    static actions = AppModel.buildActions();
    static actionCreators = AppModel.buildActionCreators(AppModel.actions);
    static reducer = AppModel.buildReducer(AppModel.actions);

    static componentActionCreators = {
        ...AppModel.buildComponentUpdateActionCreators(),
        ...AppModel.buildComponentSetActiveActionCreators()
    };

    constructor() {
        super();
        this.configuration = {};
        this.isDisconnected = false;
    }

    static buildActions() {
        return {
            UPDATE_CONFIGURATION: 'UPDATE_APP_CONFIGURATION',

            SET_DISCONNECTED: 'SET_DISCONNECTED',
            SET_CONNECTED: 'SET_CONNECTED',
            // GLOBAL SAGA ACCESS
            YIELD_EFFECT_DESCRIPTOR: 'YIELD_EFFECT_DESCRIPTOR',
            HANDLE_APP_ERROR: 'HANDLE_APP_ERROR'
        }
    }

    static buildActionCreators(actions) {
        return {
            updateConfiguration: this.actionCreator(actions.UPDATE_CONFIGURATION, 'configuration'),

            setDisconnected: this.actionCreator(actions.SET_DISCONNECTED),
            setConnected: this.actionCreator(actions.SET_CONNECTED),
            // GLOBAL SAGA ACCESS ACTION CREATORS
            yieldEffectDescriptor: this.actionCreator(actions.YIELD_EFFECT_DESCRIPTOR, 'effectDescriptor'),
            handleError: this.actionCreator(actions.HANDLE_APP_ERROR, 'error', 'callbacks')
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'app',
                type: 'App',
                state: {
                    isLoading: true
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'APP',
                type: 'App'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new this(), action) {
            switch (action.type) {
                case actions.UPDATE_CONFIGURATION: {
                    const {configuration} = action.payload;
                    return {
                        ...state,
                        configuration: {
                            ...state.configuration,
                            ...configuration
                        }
                    };
                }
                case actions.SET_DISCONNECTED: {
                    return {
                        ...state,
                        isDisconnected: true
                    };
                }
                case actions.SET_CONNECTED: {
                    return {
                        ...state,
                        isDisconnected: false
                    };
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class AppApi {

    static getServices() {
        return;
        // return axiosProxy.get('/services');
    }
}

export default AppModel;