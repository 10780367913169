import React from "react";
import "./TabbedPage.css";
import Label from "../Label/Label";

export function PageHeader(props) {
    const {
        title,
        description
    } = props;

    return (
        <>
            <h1 className="subtitle is-5 is-bold">
                {title}
            </h1>
            {description &&
                <Label Tag={'p'} value={description}/>
            }
        </>
    );
}