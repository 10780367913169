import {all, call, put, race, select, take} from "redux-saga/effects";
import {deepCopy, getValues, switchcase} from "../../helpers/functions";
import {contextCall} from "../../helpers/sagas";
import {SagaRunnable} from "./SagaRunnable";
import ReduxModel from "../models/ReduxModel";
import PopupModel from "../models/app/PopupModel";
import {statuses} from "../../helpers/constants";

class BaseSaga extends SagaRunnable {

    // Model Class
    static ModelType = class extends ReduxModel {
        static nom;
        static actions;
        static actionCreators;
        static componentActionCreators;

        constructor() {
            super();
            throw new Error("Missing ModelClass");
        }
    };
    static ModelApi = {};
    static route = '/';

    static activationComponent = '';
    static variableNames = {
        mapName: 'baseMap',
        modelName: 'name',
        formState: 'baseForm',
        updateView: 'updateView'
    };

    static translations = {
        discardFormKey: 'discardForm_#####',
        deleteItemKey: 'deleteItem_#####'
    };

    static getModelName() {
        return this.variableNames.modelName;
    }

    static* showForm(action) {
        const {initialState} = action.payload;

        yield put(this.ModelType.componentActionCreators.resetForm());
        yield put(this.ModelType.componentActionCreators.updateForm({
            ...this.ModelType.buildDefaultFormState(yield select(), initialState),
            ...initialState
        }));
        this.history.replace(this.route + '/add');
    }

    static* hideForm(action) {
        yield put(this.ModelType.componentActionCreators.resetForm());
        this.history.replace(this.route);
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield call(this.ModelApi.post, saveValues);
        yield put(this.ModelType.actionCreators.addDetails(data));
        this.history.replace(this.route + '/' + data.id);
    }

    static showView(action) {
        const {id} = action.payload;
        this.history.replace(this.route + '/' + id);
    }

    static hideView() {
        this.history.replace(this.route);
    }

    static* toggleEnabled(action) {
        const {mapName} = this.variableNames;

        const {id} = action.payload;
        const {enabled} = yield select(state => state[mapName].get(id));

        const {data} = yield call(this.ModelApi.putDetails, id, {enabled: !enabled});
        yield put(this.ModelType.actionCreators.updateDetails({[id]: {enabled: data.enabled}}));
    }

    static* duplicate(action) {
        const {mapName} = this.variableNames;

        const {id} = action.payload;
        const editValues = yield contextCall(this, 'getEditValues', id);

        const map = yield select(state => state[mapName]);
        const modelName = this.getModelName(editValues);
        editValues[modelName] = yield contextCall(this, 'getUniqueName', editValues[modelName], map);

        const duplicateValues = yield contextCall(this, 'getDuplicateValues', editValues);
        // Track initialValues
        const initialValues = deepCopy(duplicateValues);

        yield all([
            put(this.ModelType.actionCreators.hideView()),
            put(this.ModelType.actionCreators.showForm({
                initialValues,
                ...duplicateValues
            }))
        ]);
    }

    static getDuplicateValues(editValues) {
        editValues.isAddEnabled = true;
        return editValues;
    }

    static getSaveValues(values) {
        // Default implementation is to return values
        return values;
    }

    static* test(action) {
        const {id} = action.payload;
        const {data: status} = yield call(this.ModelApi.test, id);
        // Update status object for model
        yield put(this.ModelType.actionCreators.updateDetails({
            [id]: {status}
        }));

        const model = yield select(state => state[this.variableNames.mapName].get(id));
        yield contextCall(this, 'handleTestStatus', status, {name: model.name});
    }

    static* handleTestStatus(status, opts={}) {
        const [showPopup, popupKey] = switchcase({
            [statuses.ERROR]: ['showError', 'testError'],
            [statuses.WARNING]: ['showWarning', 'testWarning'],
            [statuses.MISSING]: ['showWarning', 'testWarning'],
            [statuses.UNKNOWN]: ['showWarning', 'testWarning'],
            [statuses.INFO]: ['showInfo', 'testInfo'],
            [statuses.OK]: ['showSuccess', opts.successPopupKey || 'testSuccess']
        })()(status.code);

        const cancelButton = {titleKey: 'common:option.ok'};
        if (status.message) {
            yield put(PopupModel.actionCreators[showPopup]({
                info: {
                    key: 'testStatusMessage',
                    values: status
                },
                cancelButton
            }));
        } else {
            yield put(PopupModel.actionCreators[showPopup]({
                info: {
                    key: popupKey,
                    values: {
                        name: opts.name
                    }
                },
                cancelButton
            }));
        }
    }

    static* promptDelete(action) {
        const {id} = action.payload;
        const model = yield select(state => state[this.variableNames.mapName].get(id));

        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: this.translations.deleteItemKey,
                values: {
                    name: model.name
                },
                valueKeys: {
                    itemTitle: this.translations.itemTitle
                }
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: dispatch => dispatch(this.ModelType.actionCreators.delete(id))
            }]
        }));
    }

    static* delete(action) {
        const {id} = action.payload;

        yield call(this.ModelApi.delete, id);
        yield all([
            put(this.ModelType.actionCreators.deleteDetails(id)),
            put(this.ModelType.actionCreators.hideView())
        ]);
    }

    //=====================================================UTILITY====================================================\\

    static* showConfirmPopup(info, options={}) {
        const popupId = `confirmPopup${info.key}`;
        const {
            confirmTitleKey='common:option.ok'
        } = options;

        yield put(PopupModel.actionCreators.show({
            id: popupId,
            info,
            buttons: [{
                titleKey: confirmTitleKey,
                onClick: () => {
                    this.dispatch({type: 'CONFIRM_SAVE'})
                }
            }]
        }));

        do {
            const [confirmed, hidden] = yield race([
                take('CONFIRM_SAVE'),
                take(PopupModel.actions.HIDE)
            ]);

            if (confirmed || hidden.payload.id === popupId) {
                return !!confirmed;
            }
        } while (true);
    }

    static* showSaveWarning(info, options={}) {
        const popupId = `saveWarning${info.key}`;
        const {
            confirmTitleKey='common:option.save',
            throwError=true
        } = options;

        yield put(PopupModel.actionCreators.showWarning({
            id: popupId,
            info,
            buttons: [{
                titleKey: confirmTitleKey,
                onClick: () => {
                    this.dispatch({type: 'CONFIRM_SAVE'})
                }
            }],
            cancelButton: {
                onClick: () => {
                    this.dispatch({type: 'CANCEL_SAVE'})
                },
                titleKey: 'common:option.cancel'
            }
        }));

        const [confirmed, rejected] = yield race([
            take('CONFIRM_SAVE'),
            take('CANCEL_SAVE')
        ]);

        if (rejected && throwError) {
            throw Error('save was rejected by user');
        }
        return !!confirmed;
    }

    static getUniqueName(name, mapName) {
        const copyRegex = / - Copy$/;
        const copyDigitRegex = / - Copy \([0-9]+\)$/;

        const nameList = getValues(mapName)
            .map(model => model.name);

        let duplicateName = name;
        while (nameList.includes(duplicateName)) {

            const copyDigitMatch = duplicateName.match(copyDigitRegex);
            if (copyDigitMatch != null) {

                const digits = copyDigitMatch[0].slice(' - Copy ('.length, -1);
                const number = parseInt(digits) + 1;

                duplicateName = duplicateName.slice(0, -(digits.length + 2)) + `(${number})`;

            } else if (duplicateName.match(copyRegex)) {
                duplicateName += ' (2)';
            } else {
                duplicateName += ' - Copy';
            }
        }

        return duplicateName;
    }
}

export default BaseSaga;