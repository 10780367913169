import React from 'react';
import ReactDOM from 'react-dom';
import {byteUnits, PASSWORD_PLACEHOLDER} from "./constants";

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export function generateUUID4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
}

export function simpleCopyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function getSearchParamsAsObject(search) {
    const searchParams = new URLSearchParams(search);

    return [...searchParams.keys()].reduce((acc, key) => {
        acc[key] = searchParams.get(key);
        return acc;
    }, {});
}

export function buildClassName(...classes) {
    return classes
      .filter(_class => _class)
      .join(' ');
}

// returns array of value arrays
export function readTsvText(text) {
    return text.split(/\r?\n/)
        .map(line => line.split(/\t+/));
}

export function readLines(text) {
    return text.split(/\r?\n/)
        .map(line => line.trim());
}

export function validateRegex(regex, value) {
    return (regex == null || regex === '' || getRegexMatches(regex, value));
}

function getRegexMatches(regex, value) {
    try {
        return value.match(`^(${regex})$`);
    } catch(error) {
        return true;
    }
}

export function getProtectedValue(value) {
    return (value == null) ? PASSWORD_PLACEHOLDER : value;
}

export function postProtectedValue(value) {
    return (value === PASSWORD_PLACEHOLDER) ? null : value;
}

//Use to check if obj contains deep nested levels (e.g. obj: {level1: {level2: {level3: {}}}} -> (obj, level1, level2, level3))
export function checkNestedExists(obj, level, ...levels) {
    if (obj == null) return false;
    if (levels.length === 0 && obj.hasOwnProperty(level)) return obj[level] != null;
    return checkNestedExists(obj[level], ...levels);
}

//true IFF obj not empty/falsy or has elements which are not empty/falsy (e.g. {a: {b: 'test'}} -> true, {a: {b: {c: 100}} -> true)
//false IFF obj is empty/falsy or has all elements which are empty/falsy (e.g. {} -> false, {a: {}} -> false, {a: {b: {c: {}}} -> false)
export function isNotEmptyNorFalsy(obj) {
    if (!obj) {
        return false;
    }
    if (obj && !['object', 'array'].includes(typeof obj)) {
        return true;
    }

    return getValues(obj).some(val => isNotEmptyNorFalsy(val));
}

export function arrayIsNotEmptyNorFalsy(arr) {
    return Array.isArray(arr) && arr.length > 0 && isNotEmptyNorFalsy(arr);
}

export function getGeneralizedItemKey(item) {
    return item.key || item.value;
}

//Gets all string values in object and accumulate them into one string
export function getObjectText(obj, options={}) {
    if (obj == null)
        return '';
    const {blacklist=[], whitelist=[]} = options;

    return getEntries(obj)
      .filter(([key]) => !blacklist.includes(key))
      .filter(([key]) => whitelist.length === 0 || whitelist.includes(key))
      .reduce((acc, [ignore, val]) => {
          if (typeof val === 'string')
              return acc + val;

          if (typeof val === 'object')
              return acc + getObjectText(val, options);

          return acc;
      }, '').toLowerCase();
}

export function mapIntoSet(arr, {key, initialValue=true}={}) {
    return arr.reduce((acc, curr) => {
        if (key != null) {
            acc[curr[key]] = initialValue;
        } else {
            acc[curr] = initialValue;
        }
        return acc;
    }, {});
}

export function mapIntoObj(arr, key='id') {
    return arr.reduce((acc, curr) => {
        acc[curr[key]] = curr;
        return acc;
    }, {});
}

//Returns whether arr1 has all elements in arr2
export function includesAll(arr1, arr2) {
    return arr2.every(e => arr1.includes(e));
}

//Returns whether arr1 has at least one element in arr2
export function includesSome(arr1, arr2) {
    return arr2.some(e => arr1.includes(e));
}

export function objIncludesAll(obj, ...e) {
    return includesAll(getValues(obj), e);
}

export function objIncludesSome(obj, ...e) {
    return includesSome(getValues(obj), e);
}

//Returns whether str endsWith some of arr
export function endsWithSome(str, suffixes) {
    return suffixes.some(suffix => str.endsWith(suffix));
}

//Comparison function for two objects with string name properties
export function nameLocaleCompare(objA, objB) {
    if (!(checkNestedExists(objA, 'name') && typeof objA.name === 'string' && checkNestedExists(objB, 'name') && typeof objB.name === 'string')) {
        return 0;
    }
    return objA.name.localeCompare(objB.name);
}

export function binarySearch(arr, el, comparator) {
    if (comparator(el, arr[0]) < 0) {
        return 0;
    }
    if (comparator(el, arr[arr.length - 1]) > 0) {
        return arr.length;
    }

    let lo = 0;
    let hi = arr.length;
    while (lo < hi) {
        const mid = (lo + hi) >> 1;
        const cmp = comparator(el, arr[mid]);

        if (cmp < 0) {
            hi = mid;
        } else if ( cmp > 0) {
            lo = mid + 1;
        } else {
            return mid;
        }
    }

    return hi;
}

//Checks whether objects are equal (prop order dependent)
export const objEquals = (obj1, obj2, opts) => {
    if (obj1 === obj2)
        return true;

    if (obj1 instanceof Object && obj2 instanceof Object) {
        const obj1Entries = getEntries(obj1);
        const obj2Entries = getEntries(obj2);

        if (obj1Entries.length !== obj2Entries.length)
            return false;

        for (let i = 0; i < obj1Entries.length; i++) {
            const [key1, val1] = obj1Entries[i];
            const [key2, val2] = obj2Entries[i];

            if (opts && Array.isArray(opts.blacklist) && opts.blacklist.includes(key1)) {
                continue;
            }

            if (!objEquals(key1, key2, opts) || !objEquals(val1, val2, opts))
                return false;
        }

        return true;
    }

    return obj1 === obj2;
};

export const objEqualsNotOrdered = (obj1, obj2) => {
    if (obj1 === obj2)
        return true;

    if (obj1 instanceof Object && obj2 instanceof Object) {
        const obj1Keys = getKeys(obj1);
        const obj2Keys = getKeys(obj2);

        if (obj1Keys.length !== obj2Keys.length)
            return false;

        for (let i = 0; i < obj1Keys.length; i++) {
            const key = obj1Keys[i];

            const val1 = obj1[key];
            const val2 = obj2[key];

            if (!objEqualsNotOrdered(val1, val2))
                return false;
        }

        return true;
    }

    return obj1 === obj2;
}

export const stringToBool = str =>
    str === 'true' || str === true;

export const boolToString = bool =>
    bool ? 'True' : 'False';

//Used to compare strings as numbers (for textInputs used for numbers but return values as strings)
//return 1 if num1 > num2, 0 if ===, -1 otherwise
export const compareNumStrings = (num1, num2) => {
    const int1 = parseInt(num1);
    const int2 = parseInt(num2);

    if (int1 > int2) return 1;
    if (int1 === int2) return 0;
    return -1;
};

//Transforms an array into a Map using specified key parameter
export const arrayIntoMap = (arr, key) => {
    const map = new Map();
    arr.forEach(e => {
        map.set(e[key], e);
    });

    return map;
};

export function arrayIntoBoolObject(arr) {
    return arr.reduce((obj, e) => {
        obj[e] = true;
        return obj;
    }, {});
}

export function boolObjectToArray(obj, blacklist) {
    return getEntries(obj)
        .filter(([key, val]) => (!blacklist || !blacklist.includes(key)) && val)
        .map(([key]) => key);
}

//toggle -> If element exists REMOVE, otherwise ADD
export const getNewArrayWithToggledElement = (arr, element) => {
    return arr ? arr.includes(element) ? arr.filter(e => e !== element) : [...arr, element] : [element];
};

export const getNewArrayWithUpdatedValue = (arr, element, index) => {
    const newArr = arr.slice();
    newArr[parseInt(index)] = element;

    return newArr;
};

export const filterArrayIndices = (arr, indices) => {
    return arr.filter((e, index) => !indices.includes(index.toString()));
};

export const cleanFalsy = (arr) =>
    arr.filter(e => e);

//get all keys with 'truthy' values
//Object is used as a set with the values being set as the object keys with a truthy value. They're removed by setting their values to falsy
export const objectTruthyValues = obj =>
    getKeys(obj).filter(key => obj[key]);

export const genObjHashCode = obj => {
    const str = JSON.stringify(obj);
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const code = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + code;
        hash |= 0;
    }
    return hash;
};

export const deepCopy = obj => {
    if (typeof obj !== 'object') {
        return obj;
    }

    const copy = new obj.constructor();
    const entries = getEntries(obj);

    for (let i = 0; i < entries.length; i++) {
        const [key, val] = entries[i];

        let value;
        if (val != null && typeof val === 'object') {
            value = deepCopy(val);
        } else {
            value = val;
        }

        if (copy instanceof Map) {
            copy.set(key, value);
        } else {
            copy[key] = value;
        }
    }
    return copy;
};

export function mergeDefined(obj1, obj2) {
    Object
        .keys(obj1)
        .filter(prop => obj2[prop] != null)
        .forEach(prop => {
            obj1[prop] = obj2[prop];
        });

    return obj1;
}

export function mergeUndefined(obj1, obj2) {
    Object
        .keys(obj1)
        .filter(prop => obj1[prop] == null)
        .forEach(prop => {
            obj1[prop] = obj2[prop];
        });

    return obj1;
}

export function deepMerge(...objects) {
    const isObject = function(obj) {
        return obj && typeof obj === 'object';
    };

    return objects.reduce((prev, obj) => {
        const keys = getKeys(obj);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = [...pVal, ...oVal].filter((e, i, arr) => arr.indexOf(e) === i);
            } else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = deepMerge(pVal, oVal);
            } else {
                prev[key] = oVal
            }
        }

        return prev;
    }, {});
}

export function mergeMaps(...maps) {
    return new Map(function*() {
        for (let i = 0; i < maps.length; i++) {
            yield* maps[i];
        }
    }());
}

export function getOrDefault(obj, key, defaultValue) {
    let val;
    if (obj instanceof Map) {
        val = obj.get(key);
    } else {
        val = obj[key];
    }

    if (val == null)
        return defaultValue;

    return val;
}

export function getOrSetDefault(obj, key, defaultValue) {
    let val;
    if (obj instanceof Map) {
        val = obj.get(key);

        if (val == null) {
            obj.set(key, defaultValue);
            return obj.get(key);
        }

    } else {
        val = obj[key];

        if (val == null) {
            obj[key] = defaultValue;
            return obj[key];
        }
    }

    return val;
}

export function spreadNonNullObj(objA, objB) {
    let obj;
    if (Array.isArray(objA)) {
        obj = [...objA];
    } else {
        obj = {...objA};
    }

    // Only update non-null values
    getEntries(objB)
      .filter(([ignore, v]) => v != null)
      .forEach(([k, v]) => {

          if (obj[k] != null && typeof v === 'object') {
              // recursive for object children
              obj[k] = spreadNonNullObj(obj[k], v)
          } else {
              obj[k] = v;
          }
      });

    return obj;
}

export function getEntries(obj) {
    if (obj instanceof Map) {
        return [...obj.entries()];
    }
    return getKeys(obj).map(key => ([key, obj[key]]));
}

export function getKeys(obj) {
    if (obj instanceof Map) {
        return [...obj.keys()];
    }
    return Object.keys(obj || {});
}

export function getValues(obj) {
    if (obj instanceof Map) {
        return [...obj.values()];
    }
    return getKeys(obj).map(key => obj[key]);
}

// Check if all values are truthy
export function isAllTruthy(obj) {
    return getValues(obj).every(val => !!val);
}

// Check if some values are truthy
export function isSomeTruthy(obj) {
    return obj === true || getValues(obj).some(val => !!val);
}

//Builds an object with index-to-index mapping of keys and values
export const buildObj = (...keys) => (...values) => {
    const obj = {};
    for (let i = 0; i < keys.length; i++) {
        obj[keys[i]] = values[i];
    }
    return obj;
};

export const getProfileTypeFromXml = profileXml => {
    return new DOMParser().parseFromString(profileXml, 'text/xml').children[0].tagName;
}

//4 32-bit ints => 128 bits
export const generateSecureRandomKey = () => {
    const randomIntArray = new Uint32Array(4);
    window.crypto.getRandomValues(randomIntArray);

    const secureRandomKey = [];
    randomIntArray.forEach(randomInt => secureRandomKey.push(randomInt.toString(16)));

    return secureRandomKey.join('-');
};

//Avoid rendering to root element? or find better way to render form to submit
export const submitFieldsViaForm = (url, formAttr, fields) => {
    ReactDOM.render(<form action={url} method="POST" {...formAttr}>
        {fields.map((field, i) =>
            <input key={field.name || i} type="hidden" {...field}/>
        )}
    </form>, document.getElementById('root')).submit();
};

export function convertRemToPixels(rem) {
    const fontSizePx = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return rem * fontSizePx;
}

export function convertPixelsToRem(px) {
    const fontSizePx = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return px / fontSizePx;
}

export function getBytesReadableUnit(size, isIbi) {
    size = +size;

    if (size === 0)
        return '0 B';

    const unitSize = isIbi ? 1024 : 1000;
    const suffixes = isIbi ? ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'] : byteUnits;
    const pow = Math.floor(Math.log(size) / Math.log(unitSize));

    return suffixes[pow];
}

export function getBytesPowAndSuffix(size) {
    size = +size;

    let pow;
    if (size === 0) {
        pow = 0;
    } else {
        pow = Math.floor(Math.log(size) / Math.log(1000));
    }

    if (pow >= byteUnits.length) {
        pow = byteUnits.length - 1;
    }

    const suffix = byteUnits[pow];
    if (suffix == null) {
        return {pow: 0, suffix: byteUnits[0]}
    }
    return {pow, suffix};
}

export function bytesCountToReadableCount(size) {
    const {suffix, pow} = getBytesPowAndSuffix(size);
    const count = (size / Math.pow(1000, pow))
      // Only show decimals when greater than KiB
      .toFixed((pow > 2) ? 2 : 0);

    return `${count} ${suffix}`;
}

export function getParentDatasetAttr(element, attr) {
    let parent = element.parentNode, value = null;
    while (value == null && parent != null) {
        value = parent.dataset && parent.dataset[attr];
        parent = parent.parentNode;
    }
    return value;
}

export function getElementAndDescendantCssText(element) {
    const allElements = [element, ...element.getElementsByTagName('*')];

    return [...document.styleSheets]
        .flatMap(cssStyleSheet => [...cssStyleSheet.cssRules])
        // Return cssRules whose selectorText matches an element in allElements
        .filter(cssRule => allElements.some(el => el.matches(cssRule.selectorText)))
        .map(cssRule => cssRule.cssText)
        .join('\n');
}

// Depth remove all className from nodes
export function removeClassFromNodes(node, className) {
    if (node == null)
        return;

    for (const child of node.querySelectorAll('*')) {
        child.classList.remove(className);
    }
}

// find parentNode with @className
export function findParentNodeWithClass(node, className) {
    let {parentNode} = node;

    while (parentNode != null && !parentNode.className.includes(className)) {
        parentNode = parentNode.parentNode;
    }

    return parentNode;
}

// Depth first search into DOM Node to find nodes of nodeName = 'LABEL'
export function findLabelNodes(node) {
    return [...node.querySelectorAll('*')]
        .filter(_node => _node.nodeName === 'LABEL');
}

// Depth first search into React.Element to find an element type 'label'
export function findReactLabelElements(element) {
    const {type, props: {children}} = element;

    if (type === 'label')
        return [element];

    const labels = [];
    if (children != null) {
        let childList = [children].flat(Infinity).filter(c => !!c);

        for (let i = 0; i < childList.length; i++) {
            labels.push(
                ...findReactLabelElements(childList[i])
            );
        }
    }

    return labels;
}

// Gets string content from React.Element
export function getReactElementTextContent(element, searchKey) {
    if (!React.isValidElement(element))
        return "";

    let text;
    if (!!searchKey) {
        text = getTextPropSearchKey(element.props, searchKey);
    }

    return !!text ? text : getTextProp(element.props);
}

export function getTextProp(item) {

    let text = "";
    for (const [key, val] of getEntries(item)) {

        if (['name', 'data-name', 'children'].includes(key) && typeof val === 'string')
            return val;

        if (!key.startsWith('_') && typeof val === 'object')
            text += getTextProp(val);
    }

    return text;
}

export function getTextPropSearchKey (item, searchKey) {
    let text = "";
    for (const [key, val] of getEntries(item)) {

        if (searchKey === key && typeof val === 'string')
            return val

        if (!key.startsWith('_') && typeof val === 'object')
            text += getTextPropSearchKey(val, searchKey);
    }

    return text;
}

//Finds first parent with scrollY
export function getScrollElement(node) {
    if (!node)
        return;

    const overflowY = node instanceof HTMLElement && window.getComputedStyle(node).overflowY;
    const isScrollable = !['visible', 'hidden'].includes(overflowY);

    if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }
    return getScrollElement(node.parentNode) || document.body;
}

//Capitalize first letter of string and lower case rest
export const capitalize = str =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();


export const capitalizeFirstLetter = str => 
    str.charAt(0).toUpperCase() + str.slice(1);

export const lowerCaseFirstLetter = str =>
    str.charAt(0).toLowerCase() + str.slice(1);

export const removeUnderlineCapitalizeAll = eventType =>
    eventType.split('_').map(s => capitalize(s)).join(' ');

export const camelCase = str => {
    return str.split('_').map((e, i) => i === 0 ? e.toLowerCase() : capitalize(e)).join('');
}

//If function, execute it, otherwise don't
export const executeIfFunction = f =>
    typeof f === 'function' ? f() : f;

//Functional version of a switch statement --- cases must be in key-value form (case: result) 
export const switchcase = cases => defaultCase => key => 
    cases.hasOwnProperty(key) ? cases[key] : defaultCase;

//Executes if result if it is a function
export const switchcaseF = cases => defaultCase => key => 
    executeIfFunction(switchcase(cases)(defaultCase)(key));

export const switchcaseArray = cases => defaultCase => key => {
    const foundKey = getKeys(cases).find(prop => prop.split(' ').includes(key));
    return foundKey ? cases[foundKey] : defaultCase;
};

//Reverse an array while keeping the original intact
export const reverseArray = arr => 
    arr.map(arr.pop, [...arr]);

export const reverseMap = map => getEntries(map)
    .reduce((acc, [key, value]) => {
        acc[value] = key;
        return acc;
    }, {});

export function getReadableTimeInterval(milliseconds, {t}) {
    const duration = {
        years: Math.floor(milliseconds / 1000 / 60 / 60 / 24 / 7 / 4 / 12),
        months: Math.floor((milliseconds / 1000 / 60 / 60 / 24 / 7 / 4) % 12),
        weeks: Math.floor((milliseconds / 1000 / 60 / 60 / 24 / 7) % 4),
        days: Math.floor((milliseconds / 1000 / 60 / 60 / 24) % 7),
        hours: Math.floor((milliseconds / 1000 / 60 / 60) % 24),
        minutes: Math.floor((milliseconds / 1000 / 60) % 60),
        seconds: Math.floor((milliseconds / 1000) % 60)
    };

    // noinspection EqualityComparisonWithCoercionJS
    const translatedDurations = getKeys(duration)
        // eslint-disable-next-line eqeqeq
      .filter(key => duration[key] != 0)
      .map(key => {

          const val = duration[key];
          return t(`common:duration.${key}`, {count: val});
      })

    if (translatedDurations.length > 1) {
        return translatedDurations.slice(0, -1).join(', ') + ` ${t('common:label.and')} ` + translatedDurations.slice(-1)
    }
    return translatedDurations.join('');
}

export function getTimeSince(utc) {
    const locale = localStorage.getItem("i18nextLng") || {};
    const rtf = new Intl.RelativeTimeFormat(locale, {numeric: 'auto'});

    // rtf requires negative for times in the past
    const secondsElapsed = ((utc == null ? new Date() : new Date(utc)) - new Date()) / 1000;

    if (Math.trunc(secondsElapsed / 60) === 0) {
        return rtf.format(Math.trunc(secondsElapsed), 'seconds');

    } else if (Math.trunc(secondsElapsed / 3600) === 0) {
        return rtf.format(Math.trunc(secondsElapsed / 60), 'minutes');

    } else if (Math.trunc(secondsElapsed / 86400) === 0) {
        return rtf.format(Math.trunc(secondsElapsed / 3600), 'hours');

    } else if (Math.trunc(secondsElapsed / 604800) === 0) {
        return rtf.format(Math.trunc(secondsElapsed / 86400), 'days');

    } else if (Math.trunc(secondsElapsed / 31536000) === 0) {
        return rtf.format(Math.trunc(secondsElapsed / 604800), 'weeks');

    } else {
        return rtf.format(Math.trunc(secondsElapsed / 31536000), 'years');
    }
}

export function getLocaleDateTimeFromUTC(utc, options) {
    if (utc == null) return;
    const _utc = typeof utc === 'string' ? parseInt(utc) : utc;

    const locale = localStorage.getItem("i18nextLng") || {};
    return new Date(_utc).toLocaleString(locale, {dateStyle: 'short', timeStyle: 'short', ...options});
}

export function getLocaleDateFromUTC(utc, options) {
    if (utc == null) return;
    const _utc = typeof utc === 'string' ? parseInt(utc) : utc;

    const locale = localStorage.getItem("i18nextLng") || {};
    return new Date(_utc).toLocaleString(locale, {dateStyle: 'short', ...options});
}

export function getInputFormattedDateFromUtc(monthOffset=0, utc) {
    const date = (utc == null ? new Date() : new Date(utc));
    date.setMonth(date.getMonth() + monthOffset);

    return getInputFormattedDate(date);
}

// Returns date in YYYY-MM-DD
export function getInputFormattedDate(date) {
    return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
    ].join('-');
}

// Returns time in HH:mm:ss
export function getInputFormattedTime(date) {
    return [
        ('0' + date.getHours()).slice(-2),
        ('0' + date.getMinutes()).slice(-2),
        ('0' + date.getSeconds()).slice(-2)
    ].join(':');
}

// Returns date in YYYY-MM-DD format and time in HH:mm:ss
export function getInputFormattedDateAndTime(monthOffset = 0, utc) {
    const date = (utc == null ? new Date() : new Date(utc));
    date.setMonth(date.getMonth() + monthOffset);

    return [getInputFormattedDate(date), getInputFormattedTime(date)]
}

export function getUtcFromFormattedDate(date, time) {
    return new Date(`${date} ${time == null ? '' : time}`).getTime();
}

export function getLocaleStringFromFormattedDate(date, time) {
    const utc = getUtcFromFormattedDate(date, time);

    return getLocaleDateTimeFromUTC(utc);
}

// format: yyyyMMddTHHmmss.SSS
export function parseNuixDateToTimeInputFormat(date) {
    if (!date) {
        return '';
    }

    const hour = date.slice(9, 11);
    const minutes = date.slice(11, 13);
    const seconds = date.slice(13, 15);

    return [hour || '00', minutes || '00', seconds || '00'].join(':');
}

// format: yyyyMMddTHHmmss.SSS
export function parseNuixDateToDateInputFormat(date) {
    if (!date) {
        return '';
    }

    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return [year, month, day].join('-');
}

export function parseDateInputFormatToNuixDate(date) {
    return date.split('-').join('');
}

export function parseTimeInputFormatToNuixDate(time) {
    const split = time.split(':');
    if (split.length === 2) {
        split.push('00');
    }

    return split.join('');
}

// format: yyyyMMddTHHmmss.SSS
export function parseNuixDate(date) {
    const year = date.slice(0, 4);
    // Month starts from 0
    const month = parseInt(date.slice(4, 6)) - 1;
    const day = date.slice(6, 8);

    const hour = date.slice(9, 11);
    const minutes = date.slice(11, 13);
    const seconds = date.slice(13, 15);
    const millis = date.slice(16, 19);

    return new Date(Date.UTC(year, month, day, hour, minutes, seconds, millis));
}

export function formatNuixDate(utc) {
    return new Date(utc).toISOString().replaceAll(/[-:Z]/g, '').slice(0, -4);
}

// Requires popups with @param=count to have a _plural translation key
export function getPluralTranslations(t, key, values) {
    if (values.count != null && values.count !== 1) {
        return t(`${key}_plural`, values);
    }
    return t(key, values);
}

// Splits an array at every @param=size values
export function chunkArray(arr, size) {
    if (size === 0)
        return [];

    const chunkedArr = [];
    for (let i = 0; i < arr.length;  i += size) {
        const chunk = arr.slice(i, i + size);

        chunkedArr.push(chunk);
    }

    return chunkedArr;
}

// Add CSS to HTML (css => {class -> styles}
export function addStyleElement(classToCss) {
    const style = document.createElement('style');

    style.innerHTML = getEntries(classToCss)
        .map(([clazz, styles]) => {
            return `${clazz} {\n${styles}\n}`
        })
        .join('\n');

    document.getElementsByTagName('head')[0].appendChild(style);
}

export function prettyPrintJson(json, returnSelf) {
    const {value, valid} = safeParseJson(json);
    if (!valid) {
        if (returnSelf) return json;
        if (typeof json === 'string') return json;
        return '';
    }

    return JSON.stringify(value, null, 2);
}

export function safeParseJson(str) {
    if (str == null)
        return {valid: false};

    let value = typeof str === 'string' ? str : JSON.stringify(str);
    try {
        value = JSON.parse(value);
    } catch {
        return {valid: false};
    }

    return {
        valid: typeof value === 'object' && value != null,
        value
    }
}

export function getMapValueName(map, key) {
    return getMapValueField(map, key, 'name');
}

export function getMapValueField(map, key, field) {
    if (map.get(key) != null)
        return map.get(key)[field];
    return key;
}

export function getMapValueIdIfExists(map, id) {
    return map.get(id) != null ? id : null;
}

export function openLinkNewTab (url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
