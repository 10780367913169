import SagaRunnable from "../SagaRunnable";
import {all, call, cancel, delay, fork, put, race, select, take, takeLeading} from "redux-saga/effects";
import CurrentUserModel, {CurrentUserApi} from "../../models/app/CurrentUserModel";
import {contextCall, contextFork, contextSaga} from "../../../helpers/sagas";
import {clearUserData} from "../../../helpers/localStorage";
import PopupModel from "../../models/app/PopupModel";
import popupModel from "../../models/app/PopupModel";
import ReduxStateModel from "../../models/ReduxStateModel";
import AppModel from "../../models/app/AppModel";
import LicensesModel from "../../models/home/LicensesModel";
import ApiKeysModel from "../../models/home/ApiKeysModel";
import idleHandler from "./idleHandler";
import {routes, SLOW_QUERY_INTERVAL} from "../../../helpers/constants";
import {selectCanViewRoute} from "../../../components/app/App";
import {objectTruthyValues} from "../../../helpers/functions";
import axiosProxy from "../../../axios/AxiosProxy";

class CurrentUserSaga extends SagaRunnable {

    static activationComponent = 'APP';

    static buildActivationEffects() {
        return [
            takeLeading(CurrentUserModel.actions.INITIALIZE_USER, this.tryCatchWrapper, this.disableWrapper, CurrentUserModel.componentActionCreators.updateRegistrationPage, contextSaga(this, 'initializeUser')),
            takeLeading(CurrentUserModel.actions.CLOSE_USER_ACCOUNT, this.tryCatchWrapper, this.disableWrapper, CurrentUserModel.componentActionCreators.updateResourcesPage, contextSaga(this, 'closeUserAccount')),

            takeLeading(CurrentUserModel.actions.SET_USER_DATA, contextSaga(this, 'setCurrentUserAndRaceLogout')),
            takeLeading(CurrentUserModel.actions.LOGOUT_USER, contextSaga(this, 'userLogout'))
        ];
    }

    static* initializeUser() {
        try {
            const {organizationName, captchaValue} = yield select(state => state.componentState.registrationPage);
            const {data} = yield call(CurrentUserApi.postUserInitialize, {organizationName, captchaValue});

            // Update users organizationId
            if (data.license) {
                const organizationId = data.license.organizationId;
                yield all([
                    // set keepActive=true to show RegistrationCompletePage until user navigates away
                    put(CurrentUserModel.componentActionCreators.updateRegistrationPage({keepActive: true})),
                    put(CurrentUserModel.actionCreators.updateOrganizationId(organizationId))
                ]);
            }

            yield put(LicensesModel.actionCreators.setLicenses([data]));
        } finally {
            yield put(CurrentUserModel.componentActionCreators.updateRegistrationPage({captchaValue: null}));
        }
    }

    static* setCurrentUserAndRaceLogout(action) {
        let {userData} = action.payload;

        if (userData.apiKey != null) {
            axiosProxy.setApiKey(userData.apiKey);
        }

        try {
            // Get user session
            const {data} = yield call(CurrentUserApi.getUser);
            userData = data;

            yield put(CurrentUserModel.actionCreators.setCurrentUser(userData));
            const pollingTasks = yield contextFork(this, 'startPollingSagas', userData);
            // Validate defaultRoute
            yield contextCall(this, 'setDefaultRoute');
            yield put(CurrentUserModel.actionCreators.finishedSettingCurrentUser());

            // @race will cancel remainder sagas when a saga completes
            yield race([
                take(CurrentUserModel.actions.LOGOUT_USER),
                // expiration is in seconds epoch
                userData.expiration != null && call(idleHandler, userData.expiration - (Math.floor(Date.now() / 1000)))
            ]);
            yield cancel(pollingTasks);

        } catch (error) {
            yield put(AppModel.actionCreators.handleError(error));
            yield put(CurrentUserModel.actionCreators.finishedSettingCurrentUser());
        }
    }

    static* setDefaultRoute() {
        let defaultRoute;
        // Check if currentRoute starts with a defined route
        const viewableRoutes = yield select(state => objectTruthyValues(selectCanViewRoute(state)));
        const routeExists = viewableRoutes.some(route => this.history.location.pathname.startsWith(route));
        if (!routeExists) {
            defaultRoute = viewableRoutes[0] || routes.HOME;
        }

        if (defaultRoute != null) {
            this.history.replace(defaultRoute);
        }
    }

    static* userLogout() {
        yield put(CurrentUserModel.actionCreators.clearCurrentUser());
        let redirectUrl;
        try {
            redirectUrl = (yield call(CurrentUserApi.deleteLogin)).data.redirectUrl;
        } catch (error) {
            // User was already logged out on server
            yield put(AppModel.actionCreators.handleError(error, {responseErrorCb: error => console.log(error)}));
        }

        clearUserData();
        if (redirectUrl) {
            // No need to clear redux here since redirection restarts App
            window.location.href = redirectUrl;
        } else {
            const currentUser = yield select(state => state.currentUser);
            if (currentUser.isAuthenticated) {
                yield put(ReduxStateModel.actionCreators.clearRedux());
                yield put(PopupModel.actionCreators.show({
                    id: 'logout',
                    info: {
                        key: 'logout'
                    },
                    cancelButton: {
                        titleKey: 'common:option.ok'
                    }
                }));
            }
        }
    }

    static* closeUserAccount() {
        const {data} = yield call(CurrentUserApi.deleteUser);

        yield all([
            put(popupModel.actionCreators.showSuccess({
                info: {
                    key: 'userAccountClosed',
                    values: {message: data}
                }
            })),
            put(CurrentUserModel.actionCreators.logoutUser())
        ]);
    }

    static* pollRefreshToken(_expiration) {
        let expiration = _expiration;
        while (true) {
            const secondsTillExpired = expiration - (Date.now() / 1000);
            // Refresh 30 seconds before expiry
            const refreshDelay = Math.max(0, secondsTillExpired - 30);
            yield delay(refreshDelay * 1000);

            const {data} = yield call(CurrentUserApi.refreshToken);
            expiration = data.expiration;
        }
    }

    // Poll all w/ SLOW_QUERY_INTERVAL
    static* startPollingSagas(userData) {
        if (userData.apiKey == null && userData.expiration != null) {
            // Poll to refresh token (not for ApiKeys)
            yield fork(this.tryCatchReattemptWrapper, contextSaga(this, 'pollRefreshToken'), userData.expiration);
        }

        yield all([
            put(LicensesModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL)),
            put(ApiKeysModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL))
        ]);
    }
}

export default CurrentUserSaga;