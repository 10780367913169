import React from "react";
import {statuses} from "../../helpers/constants";
import {buildClassName, getLocaleDateTimeFromUTC, switchcase} from "../../helpers/functions";
import errorIcon from "../../resources/images/icons/error.svg";
import warningIcon from "../../resources/images/icons/warning.svg";
import infoIcon from "../../resources/images/icons/info.svg";
import successIcon from "../../resources/images/icons/success.svg";
import unknownIcon from "../../resources/images/icons/unknown.svg";
import {useTranslation} from "react-i18next";
import ExpandableContent from "./ExpandableContent/ExpandableContent";
import Label from "./Label/Label";
import ProgressRing from "./ProgressRing/ProgressRing";

export function StatusIcon(props) {
    const {t} = useTranslation(['common']);
    const {status, isDisabled, ...attr} = props;

    const icon = switchcase({
        [statuses.OK]: successIcon,
        [statuses.INFO]: infoIcon,
        [statuses.MISSING]: warningIcon,
        [statuses.WARNING]: warningIcon,
        [statuses.UNKNOWN]: unknownIcon,
        [statuses.ERROR]: errorIcon
    })()(status.code);

    const className = buildClassName(
        'icon is-small',
        isDisabled && 'is-disabled'
    );

    return (
        <span className={className} title={t(`common:status.${status.code}`)} {...attr}>
            <img src={icon} width={16} alt={status.code}/>
        </span>
    );
}

export function Icon(props) {
    const {icon, alt, width=16, isDisabled, ...attr} = props;

    const className = buildClassName(
        'icon is-small',
        isDisabled && 'is-disabled'
    );

    return (
        <span className={className} {...attr}>
            <img src={icon} width={width} alt={alt}/>
        </span>
    )
}

export function StatusLog(props) {
    const {status, isDisabled} = props;

    return (
        <>
            <StatusLogHeader status={status} isDisabled={isDisabled}/>
            <StatusLogTable status={status} isDisabled={isDisabled}/>
        </>
    )
}

export function ExpandableStatusLog(props) {
    const {status, isDisabled} = props;

    return (
        <ExpandableContent bodyStyle={{overflowX: 'auto'}} isDisabled={isDisabled}
            header={
                <StatusLogHeader status={status} isDisabled={isDisabled}/>
            }
        >
            <StatusLogTable status={status} isDisabled={isDisabled}/>
        </ExpandableContent>
    );
}

export function ExpandableProgressStatusLog(props) {
    const {t} = useTranslation(['common'])
    const {status, progress, isDisabled} = props;

    return (
        <ExpandableContent bodyStyle={{overflowX: 'auto'}} isDisabled={isDisabled}
                           header={
                               progress ?
                                   <>
                                       <ProgressRing progress={progress} style={{marginRight: "0.5rem"}}/>
                                       <Label value={t('common:label.progress')} isHeading isDisabled={isDisabled} style={{flex: 1, margin: 'auto'}}/>
                                   </>
                                   :
                                    <StatusLogHeader status={status} isDisabled={isDisabled}/>
                           }
        >
            <StatusLogTable status={status} isDisabled={isDisabled}/>
        </ExpandableContent>
    )
}

function StatusLogHeader(props) {
    const {t} = useTranslation(['common']);
    const {status, isDisabled} = props;

    return (
        <div className="status-label">
            <StatusIcon status={status} style={{marginRight: '0.5rem'}} isDisabled={isDisabled}/>
            <Label value={t(`common:status.${status.code}`)} style={{flex: 1, margin: 'auto'}}
                isHeading isDisabled={isDisabled}/>
        </div>
    )
}

function StatusLogTable(props) {
    const {status, isDisabled} = props;

    return (
        <table>
            <tbody>
            <tr>
                {status.lastUpdatedDate &&
                    <td style={{minWidth: '12rem', whiteSpace: 'nowrap', verticalAlign: 'top'}}>
                        <Label value={`${getLocaleDateTimeFromUTC(status.lastUpdatedDate)}:`} isDisabled={isDisabled}/>
                    </td>
                }
                <td>
                    <Label value={status.message} isWordWrap isDisabled={isDisabled}/>
                </td>
            </tr>
            </tbody>
        </table>
    )
}