import React from "react";
import "./ProgressRing.css";

function ProgressRing(props) {
    const {progress, size='small', ...attr} = props;

    return (
        <div className={'progress-' + size + '-radial progress-' + Math.round(progress)} {...attr}>
            <div className={'progress-' + size + '-overlay'}/>
        </div>
    )
}

export default ProgressRing;