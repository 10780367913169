import DetailsModel from "../../models/DetailsModel";
import ComponentStateModel from "../ComponentStateModel";
import axiosProxy from "../../../axios/AxiosProxy";

class ApiKeysModel extends DetailsModel {

    static nom = 'ApiKeysModel';
    static actions = ApiKeysModel.buildActions('API_KEYS');
    static actionCreators = ApiKeysModel.buildActionCreators(ApiKeysModel.actions);
    static reducer = ApiKeysModel.buildReducer(ApiKeysModel.actions);

    static componentActionCreators = {
        ...ApiKeysModel.buildComponentUpdateActionCreators(),
        ...ApiKeysModel.buildComponentSetActiveActionCreators()
    };

    constructor(model) {
        super(model);

        if (model != null) {
            this.createdDay *= 24 * 60 * 60 * 1000;
            this.lastUsedDay *= 24 * 60 * 60 * 1000;
        }
    }

    static validateFormData(formData, state) {
        return !!(formData.apiKeyName && !isNaN(formData.validityDays) && formData.validityDays > 0);
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'apiKeysForm',
                type: 'Form',
                state: {
                    isDisabled: false
                },
            }, {
                key: 'apiKeysView',
                type: 'View',
                state: {
                    isDisabled: false
                },
            }, {
                key: 'apiKeysSecret',
                type: 'Secret',
                state: {
                    id: null,
                    secret: null
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'API_KEYS_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ApiKeysApi {

    static getDetails() {
        return axiosProxy.get(`/user/external/apiKey`);
    }

    static post(data) {
        return axiosProxy.post(`/user/external/apiKey`, data);
    }

    static delete(id) {
        return axiosProxy.del(`/user/external/apiKey/${id}`);
    }
}

export default ApiKeysModel;