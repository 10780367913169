import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {PageHeader} from "../../common/PanelPage/TabbedPage";
import {useDispatch, useSelector} from "react-redux";
import CurrentUserModel from "../../../redux/models/app/CurrentUserModel";
import Label, {CopySecretLabel} from "../../common/Label/Label";
import {SettingsRowValue} from "../../common/Table/Table";
import axiosProxy, {devUrl} from "../../../axios/AxiosProxy";
import {useGetLicenseKey} from "../license/LicensesPage";

function RegistrationCompletePage() {
    const {t} = useTranslation(['registration', 'apiKeys']);
    const dispatch = useDispatch();

    // Set keepActive=false when leaving page
    useEffect(() => {
        return () => {
            dispatch(CurrentUserModel.componentActionCreators.updateRegistrationPage({keepActive: false}));
        }
    }, [dispatch]);

    const license = useSelector(state => state.licenses[0] || {});
    // Query for credentials
    const getLicenseKey = useGetLicenseKey(license);

    return (
        <>
            <PageHeader title={t('registration:label.registrationComplete')}/>
            <Label Tag={'p'} value={t('registration:message.registrationComplete')}/>
            <Label Tag={'p'} value={t('registration:message.quickGuide')}/>

            <div className="display-item">
                <Label value={t('registration:label.baselineLicense')} isHeading/>
                <Label Tag={'p'} value={t('registration:message.createdLicenseToGetYouStarted')}/>

                <div className="settings-table display-input">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('licenses:label.id')} value={license.id}/>
                        <SettingsRowValue label={t('licenses:label.key')}
                            value={(
                                <CopySecretLabel getSecret={getLicenseKey} copyTitle={t('licenses:option.copyLicense')}
                                    copyObject={{licenseId: axiosProxy.baseUrl === devUrl ? `dev.api.rampiva.services:${license.id}` : license.id}}/>
                            )}/>
                    </div>
                </div>
            </div>

            <div className="display-item">
                <Label value={t('registration:label.utilizationAccessKey')} isHeading/>
                <Label Tag={'p'} value={t('registration:message.utilizationAccessKey_1')}/>
                <Label Tag={'p'} value={t('registration:message.utilizationAccessKey_2')}/>
            </div>

            <div className="display-item">
                <Label value={t('registration:label.resources')} isHeading/>
                <Label Tag={'p'}>
                    {t('registration:message.resources_1')} <a href={'https://community.rampiva.com/c/products/baseline'} target="_blank" rel="noopener noreferrer">{t('registration:message.resources_2')}</a>{t('registration:message.resources_3')}
                </Label>
            </div>
        </>
    )
}

export default RegistrationCompletePage;