import './App.css';
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import AppModel from "../../redux/models/app/AppModel";
import {Route, Routes} from "react-router-dom";
import {DisconnectPopup, SimplePopup} from "../common/Popups/Popups";
import {routes} from "../../helpers/constants";
import Header from "./Header";
import HomePage from "../home/HomePage";
import LoginPage from "../login/LoginPage";
import LicensesPage from "../home/license/LicensesPage";
import ResourcesPage from "../home/resources/ResourcesPage";
import NotFoundPage from "./NotFoundPage";
import ApiKeysPage from "../home/apiKeys/ApiKeysPage";
import RegistrationCompletePage from "../home/registration/RegistrationCompletePage";
import {objectTruthyValues} from "../../helpers/functions";
import RegistrationPage from "../home/registration/RegistrationPage";
import ApiKeysView from "../home/apiKeys/ApiKeysView";
import ApiKeysForm from "../home/apiKeys/ApiKeysForm";

export function selectCanViewRoute(state) {
    const isUserInitialized = !!(state.currentUser.isAuthenticated && state.currentUser.organizationId);
    return {
        [routes.LICENSE]: isUserInitialized,
        [routes.API_KEYS]: isUserInitialized,
        [routes.RESOURCES]: isUserInitialized
    };
}

export function selectDefaultRoute(state) {
    const canViewRoute = selectCanViewRoute(state);
    return objectTruthyValues(canViewRoute)[0] || routes.HOME;
}

function App() {
    const dispatch = useDispatch();

    const {isLoading} = useSelector(state => state.componentState.app);
    const {isDisconnected} = useSelector(state => state.appDetails);

    const currentUser = useSelector(state => state.currentUser);
    const canViewRoute = useSelector(selectCanViewRoute);
    const showRegistrationCompletePage = useSelector(state => state.componentState.registrationPage.keepActive);

    // Set App active
    useEffect(() => {
        dispatch(AppModel.componentActionCreators.setAppActive());
        return () => dispatch(AppModel.componentActionCreators.setAppInactive());
    }, [dispatch]);

    // Remove focus on buttons after click
    const blurElementOnMouseUp = useCallback(function() {
        const activeElement = document.activeElement;
        if (['BUTTON'].includes(activeElement.tagName)) {
            activeElement.blur();
        }
    }, []);

    // Prevent text selection when shift key pressed
    const preventTextSelection = useCallback(function(event) {
        document.onselectstart = () => !event.shiftKey;
    }, []);

    useEffect(() => {
        const preventTextSelectionForEvents = ['keyup', 'keydown'];
        preventTextSelectionForEvents.forEach(e => window.addEventListener(e, preventTextSelection));
        document.body.addEventListener('mouseup', blurElementOnMouseUp);

        return () => {
            preventTextSelectionForEvents.forEach(e => window.removeEventListener(e, preventTextSelection));
            document.body.removeEventListener('mouseup', blurElementOnMouseUp);
        }
    }, [blurElementOnMouseUp, preventTextSelection]);


    return (
        <div className="App" role="main">
            {!isLoading &&
                <>
                    <Header/>

                    <div className="pages">
                        {!currentUser.isAuthenticated &&
                            <LoginPage/>
                        }

                        {currentUser.isAuthenticated && currentUser.organizationId == null &&
                            <RegistrationPage/>
                        }

                        {currentUser.isAuthenticated && currentUser.organizationId != null &&
                            <Routes>
                                <Route path={'*'} element={<NotFoundPage/>}/>
                                <Route path={routes.HOME} element={<HomePage/>}>

                                    {showRegistrationCompletePage &&
                                        <Route index element={<RegistrationCompletePage/>}/>
                                    }
                                    {canViewRoute[routes.LICENSE] &&
                                        <Route path={routes.LICENSE} element={<LicensesPage/>}/>
                                    }
                                    {canViewRoute[routes.API_KEYS] &&
                                        <Route path={routes.API_KEYS} element={<ApiKeysPage/>}>
                                            <Route path={'add'} element={<ApiKeysForm/>}/>
                                            <Route path={':id'} element={<ApiKeysView/>}/>
                                        </Route>
                                    }
                                    {canViewRoute[routes.RESOURCES] &&
                                        <Route path={routes.RESOURCES} element={<ResourcesPage/>}/>
                                    }
                                </Route>
                                }
                            </Routes>
                        }
                    </div>
                </>
            }

            <SimplePopup/>
            <DisconnectPopup isActive={isDisconnected}/>
        </div>
    );
}

export default App;