import ReduxModel from "../ReduxModel";
import ComponentStateModel from "../ComponentStateModel";
import axiosProxy from "../../../axios/AxiosProxy";

class LicensesModel extends ReduxModel {

    static nom = 'LicensesModel';
    static actions = LicensesModel.buildActions();
    static actionCreators = LicensesModel.buildActionCreators(LicensesModel.actions);
    static reducer = LicensesModel.buildReducer(LicensesModel.actions);

    static componentActionCreators = LicensesModel.buildComponentSetActiveActionCreators();

    constructor(model) {
        super();
        this.license = {};

        if (model != null) {
            this.updateAll(model.license);
            this.name = model.name;
        }
    }

    static buildActions() {
        return {
            SET_LICENSES: `SET_LICENSES`,
            START_POLLING_DETAILS: `START_POLLING_LICENSES_DETAILS`,
            STOP_POLLING_DETAILS: `STOP_POLLING_LICENSES_DETAILS`,
            QUERY_DETAILS: 'QUERY_LICENSES_DETAILS'
        };
    }

    static buildActionCreators(actions) {
        return {
            setLicenses: this.actionCreator(actions.SET_LICENSES, 'licenses'),
            startPollingDetails: this.actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: this.actionCreator(actions.STOP_POLLING_DETAILS),
            queryDetails: this.actionCreator(actions.QUERY_DETAILS)
        };
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'LICENSES_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = [], action) {
            switch (action.type) {
                case actions.SET_LICENSES: {
                    this.lastUpdated = Date.now();
                    return action.payload.licenses.map(model => new this(model));
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class LicensesApi {

    static getLicenses() {
        return axiosProxy.get(`/user/external/license`);
    }

    static getLicenseCredentials(organizationId, licenseId) {
        return axiosProxy.get(`/organization/${organizationId}/license/${licenseId}/credentials`);
    }
}

export default LicensesModel;