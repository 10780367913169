import ReduxModel from "./ReduxModel";

class DetailsModel extends ReduxModel {

    constructor(model) {
        super();
        if (model != null) {
            this.updateAll(model);
        }
    }

    static buildDefaultFormState(state, props={}) {
        return {};
    }

    static validateFormData(formData, state) {
        return true;
    }

    static buildActions(type) {
        return {
            // DETAILS ACTIONS
            SET_DETAILS_MAP: `SET_${type}_DETAILS_MAP`,
            ADD_DETAILS: `ADD_${type}_DETAILS`,
            UPDATE_DETAILS: `BULK_UPDATE_${type}_DETAILS`,
            DELETE_DETAILS: `DELETE_${type}_DETAILS`,
            CLEAR_DETAILS_MAP: `CLEAR_${type}_DETAILS_MAP`,
            DUPLICATE_DETAILS: `DUPLICATE_${type}_DETAILS`,
            // DISPLAY ACTIONS
            SHOW_FORM: `SHOW_${type}_FORM`,
            HIDE_FORM: `HIDE_${type}_FORM`,
            SHOW_VIEW: `SHOW_${type}_VIEW`,
            HIDE_VIEW: `HIDE_${type}_VIEW`,
            // EDIT ACTIONS
            SUBMIT_FORM: `SUBMIT_${type}_FORM`,
            START_EDIT: `START_${type}_EDIT`,
            PROMPT_DELETE: `PROMPT_DELETE_${type}`,
            DELETE: `DELETE_${type}`,
            TOGGLE_ENABLED: `TOGGLE_${type}_ENABLED`,
            SEND_COMMAND: `SEND_${type}_COMMAND`,
            TEST: `TEST_${type}`,
            // DETAILS POLLING ACTIONS
            START_POLLING_DETAILS: `START_POLLING_${type}_DETAILS`,
            STOP_POLLING_DETAILS: `STOP_POLLING_${type}_DETAILS`,
            QUERY_DETAILS: `QUERY_${type}_DETAILS`,
            // SETTINGS POLLING ACTIONS
            START_POLLING_SETTINGS: `START_POLLING_${type}_SETTINGS`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_${type}_SETTINGS`,
            QUERY_SETTINGS: `QUERY_${type}_SETTINGS`,

            SET_SETTINGS: `SET_${type}_SETTINGS`,
            CLEAR_SETTINGS: `CLEAR_${type}_SETTINGS`
        };
    }

    static buildActionCreators(actions) {
        return {
            // DETAILS ACTION CREATORS
            setMap: this.actionCreator(actions.SET_DETAILS_MAP, 'details'),
            addDetails: this.actionCreator(actions.ADD_DETAILS, 'details'),
            updateDetails: this.actionCreator(actions.UPDATE_DETAILS, 'idToUpdates'),
            deleteDetails: this.actionCreator(actions.DELETE_DETAILS, 'id'),
            clearMap: this.actionCreator(actions.CLEAR_DETAILS_MAP),
            duplicateDetails: this.actionCreator(actions.DUPLICATE_DETAILS, 'id'),
            // DISPLAY ACTION CREATORS
            showForm: this.actionCreator(actions.SHOW_FORM, 'initialState'),
            hideForm: this.actionCreator(actions.HIDE_FORM),
            showView: this.actionCreator(actions.SHOW_VIEW, 'id'),
            hideView: this.actionCreator(actions.HIDE_VIEW),
            // EDIT ACTION CREATORS
            submitForm: this.actionCreator(actions.SUBMIT_FORM, 'formData'),
            startEdit: this.actionCreator(actions.START_EDIT, 'id'),
            promptDelete: this.actionCreator(actions.PROMPT_DELETE, 'id'),
            delete: this.actionCreator(actions.DELETE, 'id'),
            toggleEnabled: this.actionCreator(actions.TOGGLE_ENABLED, 'id'),
            sendCommand: this.actionCreator(actions.SEND_COMMAND, 'id', 'command', 'args'),
            test: this.actionCreator(actions.TEST, 'id'),
            // DETAIL POLLING ACTION CREATORS
            startPollingDetails: this.actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: this.actionCreator(actions.STOP_POLLING_DETAILS),
            queryDetails: this.actionCreator(actions.QUERY_DETAILS),
            // SETTINGS POLLING ACTION CREATORS
            startPollingSettings: this.actionCreator(actions.START_POLLING_SETTINGS, 'id', 'period'),
            stopPollingSettings: this.actionCreator(actions.STOP_POLLING_SETTINGS, 'id'),
            querySettings: this.actionCreator(actions.QUERY_SETTINGS, 'id'),

            setSettings: this.actionCreator(actions.SET_SETTINGS, 'id', 'settings'),
            clearSettings: this.actionCreator(actions.CLEAR_SETTINGS, 'id'),
        };
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.setMapGeneric(state, details, 'id');
                }
                case actions.ADD_DETAILS: {
                    const {details} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.addDetailsGeneric(state, details, 'id');
                }
                case actions.UPDATE_DETAILS: {
                    const {idToUpdates} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.bulkUpdateDetails(state, idToUpdates);
                }
                case actions.DELETE_DETAILS: {
                    const {id} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.deleteDetails(state, id);
                }
                case actions.CLEAR_DETAILS_MAP: {
                    this.lastUpdated = Date.now();
                    return new Map();
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }

    static buildSettingsReducer(actions) {
        return function(state = new Map(), action) {
            switch (action.type) {
                case actions.SET_SETTINGS: {
                    const {id, settings} = action.payload;
                    const model = new this(settings);

                    return new Map(state).set(id, model);
                }
                case actions.CLEAR_SETTINGS: {
                    const {id} = action.payload;
                    const newState = new Map(state);
                    newState.delete(id);

                    return newState;
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export default DetailsModel;