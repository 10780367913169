import React from 'react';
import './Button.css';
import {useTranslation} from "react-i18next";
import {buildClassName} from "../../../helpers/functions";
import Label from "../Label/Label";
import plusIcon from "../../../resources/images/icons/plus.svg";
import minusIcon from "../../../resources/images/icons/minus.svg";

export function Button(props) {
    const {label, isImg, isFullWidth, isClear, isSquare, isBold, isDisabled, children, className, ...attr} = props;

    const classes = buildClassName(
        'button',
        isImg && 'is-img',
        isClear && 'is-clear',
        isSquare && 'is-square',
        isFullWidth && 'is-fullwidth',
        className
    );

    return (
        <button className={classes} disabled={isDisabled} {...attr}>
            {label &&
                <Label value={label} isBold={isBold}/>
            }
            {children}
        </button>
    )
}

export function AddButton(props) {
    return (
        <Button {...props}>
            <span className="icon">
                <img src={plusIcon} width={16} alt={props.label}/>
            </span>
        </Button>
    )
}

export function AddButtonHeader(props) {
    const {id, label, canModify, children, style, ...attr} = props;
    const {t} = useTranslation(['aria', 'common']);

    return (
        <div id={id} className="add-button-header" style={style}>
            {canModify &&
                <AddButton label={t('common:option.add')} {...attr}/>
            }
            <Label value={label} isHeading isDisabled={attr.isDisabled}/>
            {children}
        </div>
    )
}

export function AddRemoveButtons(props) {
    const {t} = useTranslation(['aria']);

    const {
        label,
        onAddClick,
        onRemoveClick,
        onEditClick,
        isAddDisabled,
        isRemoveDisabled,
        isEditDisabled,
        isBold,
        isReadOnly,
        isHeading,
        isDisabled,
        noPadding,
        className,
        ...attr
    } = props;

    const _className = buildClassName(
        'add-remove-buttons',
        isReadOnly && 'is-read-only',
        noPadding && 'no-padding'
    );

    return (
        <div className={_className} {...attr}>
            {!isReadOnly &&
            <>
                <Button title={t('aria:button.add')} name="addButton" onClick={onAddClick}
                    isImg isDisabled={isDisabled || isAddDisabled}
                >
                    <span className="icon">
                        <img src={plusIcon} alt={t('common:option.add')}/>
                    </span>
                </Button>

                <Button title={t('aria:button.remove')} name="removeButton" onClick={onRemoveClick}
                    isImg isDisabled={isDisabled || isRemoveDisabled}
                >
                    <span className="icon">
                        <img src={minusIcon} alt={t('common:option.remove')}/>
                    </span>
                </Button>
            </>
            }

            {label &&
            <Label value={label} isBold={isBold} isHeading={isHeading} isReadOnly={isReadOnly}
                isDisabled={isDisabled}/>
            }
        </div>
    )
}