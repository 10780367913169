import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import React, {useCallback, useState} from "react";
import {useCloseHandler} from "../../../helpers/hooks";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {createInputHandler, createStateHandler} from "../../../helpers/handlers";
import ApiKeysModel from "../../../redux/models/home/ApiKeysModel";
import apiKeyIcon from "../../../resources/images/icons/apiKey.svg";

function ApiKeysForm() {
    const {t} = useTranslation(['apiKeys']);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        apiKeyName: '',
        validityDays: 365
    });

    const {isDisabled} = useSelector(state => state.componentState.apiKeysForm);

    const stateHandler = createStateHandler({
        updateState: setState
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    function onSubmit() {
        dispatch(ApiKeysModel.actionCreators.submitForm(state));
    }

    const onClose = useCloseHandler({
        values: state,
        popupKey: 'discardForm_apiKeys',
        blacklist: ['validityDays'],
        close: useCallback(() => dispatch(ApiKeysModel.actionCreators.hideForm()), [dispatch])
    });


    const {
        apiKeyName,
        validityDays
    } = state;

    const isAddEnabled = ApiKeysModel.validateFormData(state);

    return (
        <Form onClose={onClose} isDisabled={isDisabled}
            header={
                <FormHeader title={t('apiKeys:label.name_singular')} icon={apiKeyIcon} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('apiKeys:label._name')} name={'apiKeyName'} value={apiKeyName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired />
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('apiKeys:label.validityDays')} name={'validityDays'} value={validityDays} type={'number'}
                            onChange={inputHandler} isInvalid={isNaN(validityDays) || validityDays <= 0} isDisabled={isDisabled} isRequired />
                    </div>
                </>
            }
            footer={
                <FormFooter addTitle={t('apiKeys:option.submit')} onAddClick={onSubmit} isAddEnabled={isAddEnabled}
                    onCancel={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

export default ApiKeysForm;