import CustomModal from "../CustomModal/CustomModal";
import "./Form.css";
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";
import React from "react";
import {buildClassName} from "../../../helpers/functions";

function Form(props) {
    const {header, body, footer, width, onClose, isDisabled} = props;

    return (
        <CustomModal id="form" isActive onCancel={onClose} isDisabled={isDisabled} isRigid={isDisabled}
            header={
                <div className="form-header"
                    style={width ? {width: `calc(${width} - 1.5em + 0.75em + 2px - 1.5em)`} : {}}>
                    {header}
                </div>
            }
            body={
                <div className="form-body" style={{width}}>
                    {body}
                </div>
            }
            footer={
                <div className="form-footer">
                    {footer}
                </div>
            }
        />
    );
}

export function FormHeader(props) {
    const {title, icon, isDisabled} = props;
    const disabled = isDisabled ? ' is-disabled': '';

    return (
        <>
            <h1 className={'subtitle is-5 is-bold' + disabled}>
                {title}
            </h1>
            {icon &&
            <span className={'icon is-medium' + disabled}>
                <img src={icon} alt="" />
            </span>
            }
        </>
    )
}

export function FormFooter(props) {
    const {addTitle, onAddClick, isAddEnabled, cancelTitle, onCancel, isDisabled, className, ...attr} = props;
    const {t} = useTranslation(['common']);

    const _className = buildClassName(
        'button-group',
        className
    );

    return (
        <div className={_className} {...attr}>
            <Button id={'formFooterBackButton'} label={cancelTitle || t('common:option.cancel')}
                onClick={onCancel} isDisabled={isDisabled}/>

            <Button id={'formFooterNextButton'} label={addTitle}
                onClick={onAddClick} isDisabled={!isAddEnabled || isDisabled}/>
        </div>
    )
}

export default Form;