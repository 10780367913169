import ReduxModel from "../ReduxModel";

class PopupModel extends ReduxModel {

    static nom = 'PopupModel';
    static actions = PopupModel.buildActions();
    static actionCreators = PopupModel.buildActionCreators(PopupModel.actions);
    static reducer = PopupModel.buildReducer(PopupModel.actions);

    constructor(model) {
        super();
        if (model != null) {
            this.updateAll(model);
        }
    }

    static buildActions() {
        return {
            // POPUP ACTIONS
            SHOW: `SHOW_POPUP`,
            HIDE: `HIDE_POPUP`,
            UPDATE: 'UPDATE_POPUP'
        }
    }

    static buildActionCreators(actions) {
        return {
            // POPUP ACTION CREATORS
            show: payload => ({
                type: actions.SHOW,
                payload
            }),
            showSuccess: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'success', icon: 'success', isRigid: true, cancelButton: {titleKey: 'common:option.ok', ...cancelButton}, ...rest}
            }),
            showInfo: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'info', icon: 'info', isRigid: true, cancelButton: {titleKey: 'common:option.ok', ...cancelButton}, ...rest}
            }),
            showWarning: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'warning', icon: 'warning', isRigid: true, cancelButton: {titleKey: 'common:option.cancel', ...cancelButton}, ...rest}
            }),
            showError: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'error', icon: 'error', isRigid: true, cancelButton: {titleKey: 'common:option.ok', ...cancelButton},  ...rest}
            }),
            update: (id, updates) => ({
                type: actions.UPDATE,
                payload: {id, updates}
            }),
            hide: id => ({
                type: actions.HIDE,
                payload: {id}
            })
        }
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SHOW: {
                    const id = action.payload.id;
                    const popup = new PopupModel(action.payload);

                    return new Map(state).set(id, popup);
                }
                case actions.UPDATE: {
                    const {id, updates} = action.payload;
                    const popup = state.get(id);

                    if (popup != null) {
                        return new Map(state).set(id, popup.duplicate(updates));
                    }
                    return state;
                }
                case actions.HIDE: {
                    const {id} = action.payload;

                    const newState = new Map(state);
                    newState.delete(id);

                    return newState;
                }
                default: {
                    return state;
                }
            }
        };
    }
}

export default PopupModel;
