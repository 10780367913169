import {all, call, put, take, takeEvery, takeLeading} from "redux-saga/effects";
import AppModel, {AppApi} from "../../models/app/AppModel";
import PopupModel from "../../models/app/PopupModel";
import {QUERY_INTERVAL} from "../../../helpers/constants";
import CurrentUserModel, {CurrentUserApi} from "../../models/app/CurrentUserModel";
import {contextCall, contextSaga, pollUntil} from "../../../helpers/sagas";
import SagaRunnable from "../SagaRunnable";
import {getAzureNonce} from "../../../helpers/localStorage";
import {isNotEmptyNorFalsy} from "../../../helpers/functions";

class AppSaga extends SagaRunnable {

    static activationComponent = 'APP';

    static buildActivationEffects() {
        return [
            // ACTIVATION EFFECTS
            call(this.tryCatchWrapper, contextSaga(this, 'onAppStart')),
            takeEvery(AppModel.actions.YIELD_EFFECT_DESCRIPTOR, this.tryCatchWrapper, contextSaga(this, 'yieldEffectDescriptor')),
            takeEvery(AppModel.actions.HANDLE_APP_ERROR, contextSaga(this, 'handleAppError')),
            takeLeading(AppModel.actions.SET_DISCONNECTED, contextSaga(this, 'pollConnection'))
        ]
    }

    static* handleAppError(action) {
        const {error, callbacks} = action.payload;
        yield contextCall(this, 'genericErrorHandler', error, callbacks);
    }

    static* yieldEffectDescriptor(action) {
        yield action.payload.effectDescriptor;
    }

    static* pollConnection() {
        yield pollUntil(AppModel.actions.SET_CONNECTED, QUERY_INTERVAL, this.queryConnection);
    }

    static* queryConnection() {
        try {
            yield call(AppApi.getServices);
            // If api call is successful, App is connected
            yield put(AppModel.actionCreators.setConnected());
        } catch (ignore) {
            // Ignore
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////// ON APP START

    static* onAppStart() {
        try {
            let userData;
            const path = window.location.hash.substring(1);

            if (process.env.REACT_APP_API_KEY != null) {
                // Login with environment variable API Key
                userData = {apiKey: process.env.REACT_APP_API_KEY};

            } else if (path.startsWith('/oidcResponse/')) {
                // Get state from path
                const nonce = path.replace('/oidcResponse/', '');
                if (nonce != null) {
                    const {data} = yield call(CurrentUserApi.postOidcNonce, nonce);
                    userData = data;
                }
            }

            if (isNotEmptyNorFalsy(userData)) {
                yield all([
                    take(CurrentUserModel.actions.FINISHED_SETTING_CURRENT_USER),
                    put(CurrentUserModel.actionCreators.setUserData(userData)),
                ]);
            }
        } finally {
            yield put(AppModel.componentActionCreators.updateApp({isLoading: false}));
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////// ERROR HANDLERS

    static serverErrorResponseValues(errorResponse) {
        if (typeof errorResponse.data.key === 'string') {
            return errorResponse.data;
        }

        return {
            values: {
                title: errorResponse.data.title || `HTTP/${errorResponse.status} ${errorResponse.statusText}`,
                message: errorResponse.data.message || errorResponse.data
            }
        };
    }

    // ERROR.RESPONSE - error response
    // ERROR.REQUEST && !ERROR.RESPONSE - request sent and response timed out (assume disconnected)
    // !ERROR.REQUEST - error generated without a network request (assume not network related)

    // replace generic error handling with custom callbacks for each case:
    // @genericError = !error.request
    // @responseError = error.response
    // @noResponseError = error.request && !error.response
    static* genericErrorHandler(error, errorCbs) {
        const {noRequestErrorCb, responseErrorCb, noResponseErrorCb} = errorCbs || {};

        let cb;
        if (error.request) {
            if (error.response) {
                cb = typeof responseErrorCb === 'function' ? responseErrorCb : this.handleResponseError.bind(this);
            } else {
                cb = typeof noResponseErrorCb === 'function' ? noResponseErrorCb : this.handleNoResponseError.bind(this);
            }
        } else {
            cb = typeof noRequestErrorCb === 'function' ? noRequestErrorCb : this.handleNoRequestError.bind(this);
        }

        yield call(cb, error);
    }

    static* handleResponseError(error) {
        if (error == null || error.response == null)
            return;

        if (+error.response.status === 401) {
            yield put(CurrentUserModel.actionCreators.logoutUser());
        } else {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    ...this.serverErrorResponseValues(error.response)
                }
            }));
        }
    }

    // error.request && !error.response
    static* handleNoResponseError() {
        yield put(AppModel.actionCreators.setDisconnected());
    }

    // !error.request
    static handleNoRequestError(error) {
        console.log(error);
    }
}

export default AppSaga;