import "./Header.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {IconSelectableItem} from "../common/SelectableItem/SelectableItem";
import Dropdown from "../common/Dropdown/Dropdown";
import logoutIcon from "../../resources/images/icons/logout.svg";
import CurrentUserModel from "../../redux/models/app/CurrentUserModel";
import {useRouteMatches, useUserIcon} from "../../helpers/hooks";
import {Link} from "react-router-dom";
import {selectDefaultRoute} from "./App";
import logo from "../../resources/images/logo/logo-light-portal.svg";

function Header() {
    const {t} = useTranslation(['app', 'aria']);
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.currentUser);
    const userIcon = useUserIcon(currentUser.name);

    // Link active if currentRoute != defaultRoute
    const defaultRoute = useSelector(selectDefaultRoute);
    const isLogoLinkActive = !useRouteMatches(defaultRoute, false);

    function logout() {
        dispatch(CurrentUserModel.actionCreators.logoutUser());
    }

    return (
        <header className="app-header">
            {isLogoLinkActive ?
                <Link to={defaultRoute}>
                    <img className="logo" src={logo} alt="Logo"/>
                </Link>
                :
                <img className="logo" src={logo} alt="Logo"/>
            }

            {currentUser.isAuthenticated &&
                <div className="menu-dropdown">
                    <Dropdown id={'userMenuDropdown'} title={t('aria:dropdown.userMenu')} isIconDropdown isRight
                        icon={
                            <img className="round-image" src={userIcon} alt={t('aria:image.user')}/>
                        }
                        items={
                            <IconSelectableItem key={'logoutItem'} onItemClick={logout} isLeft={false} isCentered
                                item={{
                                    key: 'logoutItem',
                                    name: t('app:option.logout', {name: currentUser.name}),
                                    icon:
                                        <img src={logoutIcon} alt={t('common:label.logout')}/>
                                }}
                            />
                        }
                    />
                </div>
            }
        </header>
    )
}

export default Header;