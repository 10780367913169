import {all, call, delay, fork, race, spawn, take, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import {QUERY_INTERVAL} from "./constants";

export function* keepAlive(saga, errorHandler, ...args) {
    const errorMillis = [];
    while (true) {
        try {
            yield call(saga, ...args);
            break;
        } catch (error) {
            if (errorHandler != null) {
                yield call(errorHandler, error);
            } else {
                console.log(error);
            }

            errorMillis.push(Date.now());
            // If 6 errors occurred within 1s, exit
            if (errorMillis[5] - errorMillis[0] < 1000) {
                console.log('Application has been stopped due to repeat error. Please refresh your browser.')
                break;
            }
            if (errorMillis.length > 6) {
                errorMillis.length = 0;
            }
        }
    }
}

export function throttle(ms, channel, saga, ...args) {
    return function* () {
        while (true) {
            const action = yield take(channel)
            yield all([
                fork(saga, ...args, action),
                delay(ms)
            ]);
        }
    }
}

export function pollUntil(pattern, period, saga, ...args) {
    return race([
        take(pattern),
        call(poll, period, saga, ...args)
    ]);
}

export function* poll(period = QUERY_INTERVAL, saga, ...args) {
    while (true) {
        yield all([
            call(saga, ...args),
            delay(period)
        ]);
    }
}

export function takeLeadingPayload(pattern, saga, ...args) {
    return fork(function* () {
        let payloadTasks = {};
        while(true) {
            const action = yield take(pattern);
            const key = JSON.stringify(action.payload);

            if (payloadTasks[key] && !payloadTasks[key].isRunning()) {
                payloadTasks[key] = null;
            }

            if (!payloadTasks[key]) {
                payloadTasks[key] = yield fork(saga, ...args.concat(action));
            }
        }
    });
}

export function* spawnSaga(saga, ...args) {
    yield spawn(saga, ...args);
}

export function contextCall(context, saga, ...args) {
    return call(contextSaga(context, saga), ...args);
}

export function contextFork(context, saga, ...args) {
    return fork(contextSaga(context, saga), ...args);
}

export function contextSpawn(context, saga, ...args) {
    return spawn(contextSaga(context, saga), ...args);
}

export function contextTakeLatest(pattern, context, saga, ...args) {
    return takeLatest(pattern, contextSaga(context, saga), ...args);
}

export function contextTakeLeading(pattern, context, saga, ...args) {
    return takeLeading(pattern, contextSaga(context, saga), ...args);
}

export function contextTakeLeadingPayload(pattern, context, saga, ...args) {
    return takeLeadingPayload(pattern, contextSaga(context, saga), ...args);
}

export function contextTakeEvery(pattern, context, saga, ...args) {
    return takeEvery(pattern, contextSaga(context, saga), ...args);
}

export function contextSaga(context, saga) {
    if (!(saga in context))
        throw Error(saga + " redux-saga does not exist in context: " + context);

    return [context, context[saga]];
}
