import {call, select, take, takeLatest, takeLeading} from "redux-saga/effects";
import BaseSaga from "./BaseSaga";
import {getEntries, getKeys, objEquals} from "../../helpers/functions";
import {contextCall, contextSaga, pollUntil, takeLeadingPayload,} from "../../helpers/sagas";
import {AppApi} from "../models/app/AppModel";
import axiosProxy from "../../axios/AxiosProxy";
import CurrentUserModel from "../models/app/CurrentUserModel";

class DetailsSaga extends BaseSaga {

    static buildActivationEffects() {
        return [
            // ACTIVATION EFFECTS
            takeLeading(this.ModelType.actions.SHOW_FORM, contextSaga(this, 'showForm')),
            takeLeading(this.ModelType.actions.HIDE_FORM, contextSaga(this, 'hideForm')),

            takeLeading(this.ModelType.actions.SHOW_VIEW, contextSaga(this, 'showView')),
            takeLeading(this.ModelType.actions.HIDE_VIEW, contextSaga(this, 'hideView')),

            takeLeading(this.ModelType.actions.SUBMIT_FORM, this.tryCatchWrapper, this.disableWrapper, this.ModelType.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),
            takeLeadingPayload(this.ModelType.actions.TOGGLE_ENABLED, this.tryCatchWrapper, contextSaga(this, 'toggleEnabled')),
            takeLeading(this.ModelType.actions.PROMPT_DELETE, contextSaga(this, 'promptDelete')),
            takeLeading(this.ModelType.actions.DELETE, this.tryCatchWrapper, this.disableWrapper, this.ModelType.componentActionCreators[this.variableNames.updateView], contextSaga(this, 'delete')),
            takeLeading(this.ModelType.actions.DUPLICATE_DETAILS, contextSaga(this, 'duplicate'))
        ];
    }

    static buildDeactivationEffects(dispatch) {
        return [
            // DEACTIVATION EFFECTS
        ];
    }

    static* detailsChangedListener() {
        let previousDetails = null;
        while (true) {
            const action = yield take(this.ModelType.actions.SET_DETAILS_MAP);

            if (!objEquals(previousDetails, action.payload.details, {blacklist: ['status']})) {
                yield contextCall(this, 'onDetailsChanged');
                previousDetails = action.payload.details;
            }
        }
    }

    static onDetailsChanged() {
        throw new Error("Method not implemented");
    }

    static* takePollDetails() {
        yield takeLatest(this.ModelType.actions.START_POLLING_DETAILS, this.tryCatchReattemptWrapper, function* (action) {
            // Wait for user initialize if not initialized
            const currentUser = yield select(state => state.currentUser);
            if (currentUser.organizationId == null) {
                yield take(CurrentUserModel.actions.UPDATE_ORGANIZATION_ID);
            }

            const {period} = action.payload;
            yield pollUntil([this.ModelType.actions.STOP_POLLING_DETAILS, CurrentUserModel.actions.LOGOUT_USER], period, contextSaga(this, 'queryDetails'), action);
        }.bind(this));
    }

    static* takePollSettings() {
        yield takeLatest(this.ModelType.actions.START_POLLING_SETTINGS, this.tryCatchReattemptWrapper, function* (action) {
            const {period} = action.payload;
            yield pollUntil([this.ModelType.actions.STOP_POLLING_SETTINGS, CurrentUserModel.actions.LOGOUT_USER], period, contextSaga(this, 'querySettings'), action);
        }.bind(this));
    }

    static queryDetails() {
        throw new Error("Method not implemented");
    }

    static querySettings() {
        throw new Error("Method not implemented");
    }

    static* queryModelNames(idToType) {
        const idList = getKeys(idToType);

        const typeToUpdate = {};
        if (idList.length > 0) {
            const response = yield call(AppApi.getNames, idList);

            if (axiosProxy.shouldUpdate('modelNames', response)) {
                for (const [id, name] of getEntries(response.data)) {
                    const type = idToType[id];
                    if (typeToUpdate[type] == null)
                        typeToUpdate[type] = {};

                    typeToUpdate[type][id] = {id, name};
                }
            }
        }
        return typeToUpdate;
    }
}

export default DetailsSaga;