import React from "react";
import "./NotFoundPage.css";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Label from "../common/Label/Label";
import {useSelector} from "react-redux";
import {selectDefaultRoute} from "./App";

function NotFoundPage() {
    const {t} = useTranslation(['common']);
    const defaultRoute = useSelector(selectDefaultRoute);

    return (
        <div className="not-found-page">
            <h1 className="title is-1">
                404
            </h1>
            <h1 className="title spaced is-2">
                {t('pageNotFound:label.pageNotFound')}
            </h1>

            <Label value={t('pageNotFound:message.description_1')} isHeading/>
            <Label isHeading>
                {t('pageNotFound:message.description_2')} <Link to={defaultRoute}>{t('pageNotFound:label.clickHere')}</Link> {t('pageNotFound:message.description_3')}
            </Label>
        </div>
    )
}

export default NotFoundPage;