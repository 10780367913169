import BaseSaga from "../BaseSaga";
import LicensesModel, {LicensesApi} from "../../models/home/LicensesModel";
import {all, call, put, select, take, takeLatest} from "redux-saga/effects";
import {contextSaga, pollUntil} from "../../../helpers/sagas";
import {details, routes, SLOW_QUERY_INTERVAL} from "../../../helpers/constants";
import axiosProxy from "../../../axios/AxiosProxy";
import ReduxStateModel from "../../models/ReduxStateModel";
import CurrentUserModel from "../../models/app/CurrentUserModel";

class LicensesSaga extends BaseSaga {

    static ModelType = LicensesModel;
    static route = routes.LICENSE;

    static activationComponent = 'LICENSES_DISPLAY';
    static variableNames = {
        mapName: 'licenses'
    };

    static buildActivationEffects() {
        return [
            put(LicensesModel.actionCreators.startPollingDetails())
        ];
    }

    static buildDeactivationEffects() {
        return [
            this.authenticatedEffects(
                put(LicensesModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL))
            )
        ];
    }

    static* takePollDetails() {
        yield takeLatest(this.ModelType.actions.START_POLLING_DETAILS, this.tryCatchReattemptWrapper, function* (action) {
            // Wait for user initialize if not initialized
            const currentUser = yield select(state => state.currentUser);
            if (currentUser.organizationId == null) {
                yield take(CurrentUserModel.actions.UPDATE_ORGANIZATION_ID);
            }

            const {period} = action.payload;
            yield pollUntil([this.ModelType.actions.STOP_POLLING_DETAILS, CurrentUserModel.actions.LOGOUT_USER], period, contextSaga(this, 'queryDetails'));
        }.bind(this));
    }

    static* queryDetails() {
        const response = yield call(LicensesApi.getLicenses);
        const key = details.LICENSE;

        if (axiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(LicensesModel.actionCreators.setLicenses(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default LicensesSaga;