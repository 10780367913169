import React, {useCallback, useEffect, useRef, useState} from "react";
import './Label.css';
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";
import {buildClassName, getEntries} from "../../../helpers/functions";
import {PASSWORD_PLACEHOLDER} from "../../../helpers/constants";
import {Icon, StatusIcon} from "../Common";
import ProgressRing from "../ProgressRing/ProgressRing";


function Label(props) {

    const {
        value,
        children,
        className,
        labelRef,
        childrenPosition='right',
        Tag='label',

        isNote,
        isCenter,
        isEllipsis,
        isWordWrap,
        isWordBreakAll,
        isNoWrap,
        isHeading,
        isBold,
        isItalic,
        isReadOnly,
        isDisabled,
        ...attr
    } = props;


    const _className = buildClassName(
        'label',
        isCenter && 'is-center',
        isNote && 'is-note',
        isEllipsis && 'is-ellipsis',
        isWordWrap && 'is-wordwrap',
        isWordBreakAll && 'break-all',
        isNoWrap && 'no-wrap',
        isHeading && 'is-heading',
        isBold && 'is-bold',
        isItalic && 'is-italic',
        isReadOnly && 'is-read-only',
        isDisabled && 'is-disabled',
        className
    );

    return (
        <Tag ref={labelRef} className={_className} {...attr}>
            {childrenPosition === 'left' && children}
            {value}
            {childrenPosition === 'right' && children}
        </Tag>
    );
}


export function CopySecretLabel(props) {
    const {t} = useTranslation(['common']);

    const {
        secret,
        getSecret,
        copyTitle,
        copyObject,
        copyButtons,
        icon,
        iconTitle,
        iconAlt,
        isDisabled,
        ...attr
    } = props;

    const _secret = useRef(secret);
    const [visible, setVisible] = useState(false);
    const [_isDisabled, _setIsDisabled] = useState(false);

    // Track secret prop
    useEffect(() => {
        if (secret != null && _secret.current !== secret) {
            _secret.current = secret;
        }
    }, [secret]);

    const updateSecret = useCallback(async () => {
        if (_secret.current == null && secret == null && typeof getSecret === 'function') {
            try {
                _setIsDisabled(true);
                _secret.current = await getSecret();
            } finally {
                _setIsDisabled(false);
            }
        }
    }, [getSecret, secret]);

    async function onCopyClick() {
        await updateSecret();

        let copyText;
        if (copyObject != null) {
            copyText = "License ID: \t" + copyObject["licenseId"] + "\nLicense Key:\t" + _secret.current
        } else {
            copyText = _secret.current;
        }

        navigator.clipboard.writeText(copyText)
            .catch(err => console.log('Failed to copy text:', err));
    }

    const toggleView = useCallback(async () => {
        if (!visible) {
            await updateSecret();
        }
        setVisible(prev => !prev);
    }, [visible, updateSecret]);

    const visibleTitle = visible ? t('common:option.hide') : t('common:option.show');
    const copyButtonTitle = copyTitle ? copyTitle : t('common:option.copy')
    const __isDisabled = isDisabled || _isDisabled;

    return (
        <div className="copy-label">
            <Label value={visible ? _secret.current : PASSWORD_PLACEHOLDER} {...attr}
                isWordBreakAll isWordWrap isDisabled={__isDisabled}>

                {!visible && icon && iconTitle &&
                    <Icon icon={icon} title={iconTitle} alt={iconAlt}
                        style={{marginLeft: '0.5rem'}} isDisabled={__isDisabled}/>
                }
            </Label>

            <div className="button-group align-start">
                {copyButtons ?
                    copyButtons
                    :
                    <Button id="copyButton" title={t('common:option.copy')} onClick={onCopyClick}
                        label={copyButtonTitle} isDisabled={__isDisabled}/>
                }

                <Button id="viewButton" title={visibleTitle} onClick={toggleView}
                    label={visibleTitle} isDisabled={__isDisabled} />
            </div>
        </div>
    )
}

export function StatusLabel(props) {
    const {status, style, ...attr} = props;

    return (
        <div className="status-label" style={style}>
            <StatusIcon status={status} style={{marginRight: '0.5rem'}} isDisabled={attr.isDisabled}/>
            <Label value={status.shortMessage} style={{flex: 1, margin: 'auto'}} {...attr}/>
        </div>
    )
}

export function ProgressStatusLabel(props) {
    const {status, progress, style, ...attr} = props;

    return (
        <div className="status-label" style={style}>
            {progress ?
                <ProgressRing progress={progress} style={{marginRight: '0.5rem'}}/>
                :
                <StatusIcon status={status} style={{marginRight: '0.5rem'}} isDisabled={attr.isDisabled}/>
            }
            <Label value={status.shortMessage} style={{flex: 1, margin: 'auto'}} {...attr}/>
        </div>
    )
}

export function LinkLabel(props) {
    const {href, label, ...attr} = props;

    return (
        <Label {...attr}>
            <a href={href} target="_blank" rel="noopener noreferrer">
                {label}
            </a>
        </Label>
    )
}

export default Label;