import React, {useState} from 'react';
import './LoginPage.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {generateSecureRandomKey} from "../../helpers/functions";
import {RAMPIVA_PORTAL_SHOW_LOGIN_BANNER, setAzureNonce} from "../../helpers/localStorage";
import axiosProxy from "../../axios/AxiosProxy";
import {Button} from "../common/Button/Button";
import microsoftLoginButton from "../../resources/images/buttons/microsoft-login-button.svg";
import CurrentUserModel from "../../redux/models/app/CurrentUserModel";
import Label, {LinkLabel} from "../common/Label/Label";
import closeIcon from "../../resources/images/icons/close-white.svg";
import linkedInIcon from "../../resources/images/icons/linkedin.svg";
import twitterIcon from "../../resources/images/icons/twitter.svg";
import youtubeIcon from "../../resources/images/icons/youtube.svg";

function LoginPage() {
    const {t} = useTranslation(['aria', 'app', 'common']);
    const dispatch = useDispatch();

    const [nonce] = useState(generateSecureRandomKey());
    const {
        showBanner,
        isDisabled
    } = useSelector(state => state.componentState.loginPage);

    function onSubmit() {
        dispatch(CurrentUserModel.componentActionCreators.updateLoginPage({isDisabled: true}));
        setAzureNonce(nonce);
    }

    function onCloseBanner() {
        dispatch(CurrentUserModel.componentActionCreators.updateLoginPage({showBanner: false}));
        localStorage.setItem(RAMPIVA_PORTAL_SHOW_LOGIN_BANNER, false);
    }

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="login-page">

            <div className="main-content">
                {showBanner &&
                    <div className="login-banner">
                        <button className="login-banner-close" title={t('aria:button.close')}
                            onClick={onCloseBanner} disabled={isDisabled}
                        >
                            <span className="icon">
                                <img src={closeIcon} alt={t('common:option.close')}/>
                            </span>
                        </button>

                        <div className="banner-content">
                            <Label Tag={'p'} value={t('app:message.welcomeToRampivaPortal')} isDisabled={isDisabled}/>
                            <Label Tag={'p'} isDisabled={isDisabled}>
                                {t('app:message.ifExperiencingIssuesContactSupport_1')} <a href="mailto:support@rampiva.com">{t('app:message.ifExperiencingIssuesContactSupport_2')}</a> {t('app:message.ifExperiencingIssuesContactSupport_3')} <a href="https://support.rampiva.com" target="_blank" rel="noopener noreferrer">{t('app:message.ifExperiencingIssuesContactSupport_4')}</a>.
                            </Label>
                        </div>
                    </div>
                }

                <form id="oidcLoginForm" action={`${axiosProxy.baseUrl}/user/external/oidcAuth`} method="POST" onSubmit={onSubmit}>
                    <input type="hidden" name="state" value={nonce}/>
                    <input type="hidden" name="apiUri" value={axiosProxy.baseUrl}/>
                    <input type="hidden" name="redirectUri" value={`${window.location.origin}/#/oidcResponse`}/>

                    <Button className="login-button" type="submit" title={t('app:option.loginMicrosoft')}
                        isFullWidth isDisabled={isDisabled}
                    >
                        <img src={microsoftLoginButton} alt={t('aria:button.microsoftLogin')}/>
                    </Button>
                </form>
            </div>

            <div className="login-footer">
                <div className={'footer-container' + disabled}>
                    <table className="footer-table">
                        <thead>
                        <tr>
                            <th><Label value={t('app:label.resources')} isBold/></th>
                            <th><Label value={t('app:label.aboutUs')}  isBold/></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>
                            <td><LinkLabel href={'https://rampiva.com/legal/portal-terms/'} label={t('app:label.termsOfUse')}/></td>
                            <td><LinkLabel href={'https://rampiva.com/about-us-vision/'} label={t('app:label.vision')}/></td>
                        </tr>
                        <tr>
                            <td><LinkLabel href={'https://rampiva.com/privacy-policy/'} label={t('app:label.privacy')}/></td>
                            <td><LinkLabel href={'https://rampiva.com/about-us-values/'} label={t('app:label.values')}/></td>
                        </tr>
                        <tr>
                            <td><LinkLabel href={'https://kb.rampiva.com/'} label={t('app:label.knowledgeBase')}/></td>
                            <td><LinkLabel href={'https://rampiva.com/about-us-team/'} label={t('app:label.team')}/></td>
                        </tr>
                        </tbody>
                    </table>

                    <div className="footer-socials">
                        <LinkIcon href={'https://www.linkedin.com/company/rampiva'} icon={linkedInIcon}/>
                        <LinkIcon href={'https://twitter.com/RampivaTech'} icon={twitterIcon}/>
                        <LinkIcon href={'https://www.youtube.com/channel/UCRIP206Pv4X1zC2avhEVTtQ'} icon={youtubeIcon}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function LinkIcon(props) {
    const {href, icon, iconAlt, ...attr} = props;

    return (
        <a className="social-link"
            href={href} target="_blank" rel="noopener noreferrer" {...attr}
        >
            <img src={icon} alt={iconAlt}/>
        </a>
    )
}

export default LoginPage;