import ReduxStateModel from "../../models/ReduxStateModel";
import DetailsSaga from "../DetailsSaga";
import ApiKeysModel, {ApiKeysApi} from "../../models/home/ApiKeysModel";
import axiosProxy from "../../../axios/AxiosProxy";
import {details, routes, SLOW_QUERY_INTERVAL} from "../../../helpers/constants";
import {all, call, put} from "redux-saga/effects";
import {contextCall} from "../../../helpers/sagas";

class ApiKeysSaga extends DetailsSaga {

    static ModelType = ApiKeysModel;
    static ModelApi = ApiKeysApi;
    static route = routes.API_KEYS;

    static activationComponent = 'API_KEYS_DISPLAY';
    static variableNames = {
        mapName: 'apiKeys',
        formState: 'apiKeysForm',
        updateView: 'updateView'
    };

    static translations = {
        discardFormKey: 'discardForm_apiKeys',
        valuesChangedKey: 'valuesChangedWhileEdit_apiKeys',
        deleteItemKey: 'deleteItem_apiKeys'
    };

    static buildActivationEffects() {
        return [
            ...super.buildActivationEffects(),
            put(ApiKeysModel.actionCreators.startPollingDetails())
        ];
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            this.authenticatedEffects(
                put(ApiKeysModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL))
            )
        ];
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield call(this.ModelApi.post, saveValues);
        yield all([
            put(this.ModelType.actionCreators.addDetails(data)),
            put(ApiKeysModel.componentActionCreators.updateSecret({
                id: data.id,
                secret: data.secret
            }))
        ]);
        this.history.replace(this.route + '/' + data.id);
    }

    static getSaveValues(values) {
        const {apiKeyName: name, validityDays} = values;
        // Default implementation is to return values
        return {
            name,
            validityDays
        };
    }

    static* queryDetails() {
        const response = yield call(ApiKeysApi.getDetails);
        const key = details.API_KEYS;

        if (axiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ApiKeysModel.actionCreators.setMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default ApiKeysSaga;