import React from 'react';
import './CustomModal.css';
import {useTranslation} from "react-i18next";
import {buildClassName} from "../../../helpers/functions";
import closeIcon from "../../../resources/images/icons/close.svg";

function CustomModal(props) {
    const {id, header, body, footer, children, isActive, onCancel, isRigid, showX = true, isDisabled, className, ...attr} = props;
    const {t} = useTranslation(['aria']);

    const onBackClick = (isDisabled || isRigid) ? null : onCancel;
    const onXClick = isDisabled ? null : onCancel;

    const _className = buildClassName(
        'custom-modal',
        isActive && ' is-active',
        className
    );

    return (
        <div id={id} className={_className} {...attr}>
            <div className="custom-modal-background" onClick={onBackClick}/>

            <div className="custom-modal-content">
                <div className="custom-modal-header">
                    {header}

                    {showX &&
                    <button id={`${id}CloseButton`} className="button is-small is-img is-clear custom-modal-close"
                        title={t('aria:button.close')} onClick={onXClick} disabled={isDisabled}
                    >
                        <span className="icon">
                            <img src={closeIcon} alt={t('common:option.close')}/>
                        </span>
                    </button>
                    }
                </div>

                {body}

                <div className="custom-modal-footer">
                    {footer}
                </div>
                {children}
            </div>
        </div>
    );
}

export default CustomModal;