import React from 'react';
import './SelectableItem.css';
import {Checkbox} from "../Checkbox/Checkbox";
import Label from "../Label/Label";
import {buildClassName, getGeneralizedItemKey} from "../../../helpers/functions";
import {useUserIcon} from "../../../helpers/hooks";

export const NameSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    return (
        <SelectableItem name={item.name} value={key} {...attr}
            isDisabled={isDisabled}
        >
            <Label value={item.name} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const TypeSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, isCentered, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    return (
        <SelectableItem name={item.name} value={key} {...attr} className={'selectable-item__type'}
            isDisabled={isDisabled}
        >
            <Label value={item.type} isItalic isDisabled={isDisabled}/>

            <Label value={item.name} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const CheckedSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;
    const key = getGeneralizedItemKey(item);

    return (
        <SelectableItem name={item.name} value={key} {...attr}
            isDisabled={isDisabled}
        >

            <Checkbox label={item.name} checked={item.isChecked} isEllipsis={isEllipsis} isWordWrap={isNoWrap == null ? isWordWrap : !isNoWrap}
                isDisabled={isDisabled} tabIndex={-1}
            />
        </SelectableItem>
    )
}

export const IconSelectableItem = props => {
    const {item, isLeft=true, isNoWrap, isDisabled, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <SelectableItem name={item.name} value={key} {...attr}
            isDisabled={isDisabled}
        >
            {isLeft &&
            <span className="icon" style={{marginRight: '0.5rem'}}>
                {item.icon}
            </span>
            }

            <label className={'label is-ellipsis' + disabled}>
                {item.name}
            </label>

            {!isLeft &&
            <span className="icon" style={{marginLeft: '0.5rem'}}>
                {item.icon}
            </span>
            }
        </SelectableItem>
    )
}

export const UserSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    const icon = useUserIcon(item.name);

    return (
        <SelectableItem name={item.name} value={key} {...attr}
            isDisabled={isDisabled}
        >
            <Checkbox checked={item.isChecked} tabIndex={-1} isDisabled={isDisabled}/>
            <span className="icon" style={{flexShrink: 0, margin: '0 0.5rem'}}>
                <img className="round-image" src={icon} alt={item.name}/>
            </span>

            <Label value={item.name} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const UserEmailSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;
    const key = getGeneralizedItemKey(item);

    return (
        <SelectableItem name={item.name} value={key} {...attr} className={'selectable-item__email'}
            isDisabled={isDisabled} title={`${item.name} / ${item.email || ''}`}
        >
            <Label value={item.name} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>

            <span className="dot-span"/>

            <Label value={item.email} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const FileSelectableItem = props => {
    const {name, onFileChange, isNoWrap, isDisabled, ...attr} = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    const wrap = isNoWrap ? ' no-wrap' : '';

    return (
        <SelectableItem isDisabled={isDisabled} {...attr}>
            <label className={'file-label' + disabled}>
                <label className={'label' + wrap}>
                    {name}
                    <input className="file-input" type="file" onChange={onFileChange} disabled={isDisabled}/>
                </label>
            </label>
        </SelectableItem>
    )
}

export const FileLibrarySelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;

    const isHeader = item.isHeader;
    const headerClassName = buildClassName('no-hover', 'no-pointer', 'no-focus');
    const labelClassName = buildClassName('label', 'is-small', isHeader ? 'is-italic' : '');

    const onItemClick = isHeader ? item.onItemClick : attr.onItemClick;
    const title = !!item.hoverTitle ? item.hoverTitle : item.name;

    return (
        <SelectableItem name={item.name} value={isHeader ? "" : item.value} data-type={isHeader ? null : item.fileType} {...attr}
                        className={isHeader ? headerClassName : ""} onItemClick={onItemClick}>

            <Label value={item.isHeader ? item.name : item.fileName} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                   isDisabled={isDisabled && !isHeader} className={labelClassName} style={isHeader ? {} : {marginLeft: '0.375rem'}} title={title}/>
        </SelectableItem>
    )
}

export function SelectableItem(props) {
    const {className, children, index, name, value, onItemClick, isSelected, isCentered, isBorder, isUnselectable, isColumn, isSpaced, isDisabled, ...attr} = props;

    const itemClassName = buildClassName(
        'selectable-item',
        className,
        isSelected && 'is-selected',
        isUnselectable && 'is-unselectable',
        isBorder && 'is-bordered',
        isCentered && 'is-centered',
        isDisabled && 'is-disabled',
        isColumn && 'is-column',
        isSpaced && 'is-spaced'
    );

    return (
        <div title={name} className={itemClassName} data-name={name} data-value={value} data-index={index}
            onClick={isDisabled ? null : onItemClick} {...attr} tabIndex={isDisabled ? -1 : 0}
        >
            {children}
        </div>
    )
}
