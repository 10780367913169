import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import {createHashHistory} from "history";
import {Provider} from "react-redux";
import configureStore from "./redux/configureStore";
import rootSaga from "./redux/rootSaga";
import i18n from "./i18next/i18n";
import CustomRouter from "./router/CustomRouter";
import SagaRunnable from "./redux/sagas/SagaRunnable";

{
    let i = i18n.isInitialized;
}

const store = configureStore();
store.runSaga(rootSaga);

SagaRunnable.history = createHashHistory();
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <CustomRouter history={SagaRunnable.history}>
                <App />
            </CustomRouter>
        </Provider>,
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
