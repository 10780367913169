import {applyMiddleware, combineReducers, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {contextCall} from "../helpers/sagas";
import axiosProxy from "../axios/AxiosProxy";
import ComponentStateModel from "./models/ComponentStateModel";
import ReduxStateModel from "./models/ReduxStateModel";
import AppModel from "./models/app/AppModel";
import PopupModel from "./models/app/PopupModel";
import CurrentUserModel from "./models/app/CurrentUserModel";
import AppSaga from "./sagas/app/AppSaga";
import LicensesModel from "./models/home/LicensesModel";
import ApiKeysModel from "./models/home/ApiKeysModel";

function configureStore() {
    const appReducer = combineReducers({
        currentUser: CurrentUserModel.reducer,
        licenses: LicensesModel.reducer,
        apiKeys: ApiKeysModel.reducer,

        appDetails: AppModel.reducer,
        popupDetails: PopupModel.reducer,
        hasLoaded: ReduxStateModel.hasLoadedReducer,
        componentState: ComponentStateModel.reducer
    });
    const rootReducer = (state, action) => {
        // Take out and reassign to the state those that should not be reset
        if (action.type === ReduxStateModel.actions.CLEAR_REDUX) {
            const {appDetails, componentState: {app}, popupDetails} = state;
            const {isDisconnected, configuration} = appDetails;

            axiosProxy.etags = {};
            state = {
                appDetails: {
                    configuration,
                    isDisconnected
                },
                componentState: {
                    ...ComponentStateModel.initialState,
                    app
                },
                popupDetails
            };
        }

        return appReducer(state, action);
    };

    const sagaMiddleware = createSagaMiddleware({
        onError: function* (error) {
            yield contextCall(AppSaga, 'genericErrorHandler', error);
        }
    });

    return {
        ...createStore(rootReducer, applyMiddleware(sagaMiddleware)),
        runSaga: sagaMiddleware.run
    };
}

export default configureStore;