import React, {useEffect, useRef, useState} from "react";
import "./Input.css";
import {buildClassName} from "../../../helpers/functions";


function HTMLTextInput(props) {
    const ref = useRef();

    const {
        inputRef,
        label,
        value,
        defaultValue,
        onChange,
        invalidMessage,
        isRequired,
        isInvalid,
        isDisabled,
        isInputDisabled,
        isReadOnly,
        isSelected,
        isHeading,
        labelStyle,
        className,
        ...attr
    } = props;

    // To start a re-render
    const [isEmpty, setIsEmpty] = useState((value || defaultValue || '').length === 0);

    useEffect(() => {
        if (inputRef != null)
            inputRef.current = ref.current;

        const refCopy = ref.current;
        // Clear input on unmount
        return () => refCopy.value = '';
    }, [inputRef, ref]);

    const refValueExists = ref.current && ref.current.value;
    useEffect(() => {
        if (ref.current != null) {
            setIsEmpty(ref.current.value.length === 0);
        }
    }, [refValueExists]);


    function onInputChange(event) {
        // Trigger onChange if defined
        typeof onChange == 'function' && onChange(event);
    }


    const labelClassName = buildClassName(
        'label',
        isDisabled && 'is-disabled',
        label && className
    );
    const inputClassName = buildClassName(
        'input',
        'is-small',
        (isRequired && isEmpty && !isDisabled) && 'is-required',
        isInvalid && 'is-invalid',
        isInputDisabled && 'is-input-disabled',
        isReadOnly && 'is-readonly',
        isSelected && 'is-selected',
        isHeading && 'is-heading',
        isDisabled && !label && 'is-disabled',
        !label && className
    );

    const input = (
        <input className={inputClassName} ref={ref} value={value} defaultValue={defaultValue} min={attr.type === 'number' ? 0 : null}
            onChange={onInputChange} disabled={isDisabled || isInputDisabled || isReadOnly} {...attr}
        />
    )

    return (
        <>
            {label ?
                <label className={labelClassName} style={labelStyle}>
                    {label}
                    {input}
                </label>
                :
                input
            }

            {invalidMessage &&
            <label className="label is-error is-wordwrap">
                {invalidMessage}
            </label>
            }
        </>
    )
}

export default HTMLTextInput;