import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import ar_AE from "./ar_AE.json";
import da_DK from "./da_DK.json";
import de_DE from "./de_DE.json";
import en_US from "./en_US.json";
import es_419 from "./es_419.json";
import fr_CA from "./fr_CA.json";
import he_IL from "./he_IL.json";
import ja_JP from "./ja_JP.json";
import ko_KR from "./ko_KR.json";
import nl_NL from "./nl_NL.json";
import pt_BR from "./pt_BR.json";
import zh_CN from "./zh_CN.json";
import axiosProxy from "../axios/AxiosProxy";
import {getKeys} from "../helpers/functions";

const resources = {
    "ar-AE": ar_AE,
    "da-DK": da_DK,
    "de-DE": de_DE,
    "en-US": en_US,
    "es-419": es_419,
    "fr-CA": fr_CA,
    "he-IL": he_IL,
    "ja-JP": ja_JP,
    "ko-KR": ko_KR,
    "nl-NL": nl_NL,
    "pt-BR": pt_BR,
    "zh-CN": zh_CN
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        interpolation: {
            escapeValue: false
        },
        lng: 'en-US',
        fallbackLng: 'en-US',
        resources
    });

export function updateLanguage(language) {
    const uiLanguage = language == null ? browserDefaultLanguage : language;

    if (i18n.language !== uiLanguage) {
        axiosProxy.setAcceptLanguageHeader(uiLanguage);
        i18n.changeLanguage(uiLanguage).then(() => {}).catch(err => console.log(err));
    }
}

export let browserDefaultLanguage;
{
    const languages = getKeys(resources);

    let browserDefault = navigator.language;
    if (!browserDefault) {
        browserDefault = "en"
    }

    if (!languages.includes(browserDefault)) {
        const language = browserDefault.slice(0, 2);
        browserDefault = languages.find(lang => lang.startsWith(language));
    }

    browserDefaultLanguage = browserDefault;
}

export default i18n;
