import React, {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";
import View, {ViewHeader} from "../../common/View/View";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getLocaleDateFromUTC, switchcaseF} from "../../../helpers/functions";
import ApiKeysModel from "../../../redux/models/home/ApiKeysModel";
import Label, {CopySecretLabel} from "../../common/Label/Label";
import {SettingsRowSeparator, SettingsRowValue} from "../../common/Table/Table";
import apiKeyIcon from "../../../resources/images/icons/apiKey.svg";
import {Button} from "../../common/Button/Button";

function selectApiKeySecret(state, apiKey) {
    const secretState = state.componentState.apiKeysSecret;
    if (secretState.id === apiKey.id) {
        return secretState.secret;
    }
}

function ApiKeysView() {
    const {t} = useTranslation(['apiKeys', 'common', 'aria']);
    const dispatch = useDispatch();

    const {id} = useParams();
    const apiKey = useSelector(state => state.apiKeys.get(id)) || {};

    const {isDisabled} = useSelector(state => state.componentState.apiKeysView);
    const onClose = useCallback(() => dispatch(ApiKeysModel.actionCreators.hideView()), [dispatch]);

    const apiKeySecret = useSelector(state => selectApiKeySecret(state, apiKey));
    // Clear apiKeySecret on componentUnMount
    useEffect(() => {
        return () => {
            dispatch(ApiKeysModel.componentActionCreators.resetSecret());
        }
    }, [dispatch]);

    function onCopyClick(event) {
        const {name} = event.currentTarget;
        const copyText = {
            'copyIdButton': id,
            'copySecretButton': apiKeySecret
        }[name];

        navigator.clipboard.writeText(copyText)
            .catch(err => console.log('Failed to copy text:', err));
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;
        switchcaseF({
            'promptDelete': () => dispatch(ApiKeysModel.actionCreators.promptDelete(apiKey.id))
        })()(value);
    }

    const menuOptions = [
        {name: t('common:option.delete'), value: 'promptDelete', isDisabled}
    ];

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <View onClose={onClose} isDisabled={isDisabled}
            header={
                <ViewHeader typeIcon={apiKeyIcon} name={apiKey.name}
                    canModify menuOptions={menuOptions} menuOptionHandler={menuOptionHandler} isDisabled={isDisabled}/>
            }
            body={
                <div className={'settings-table display-item' + disabled}>
                    <div className="table-row-group">
                        <SettingsRowValue label={t('apiKeys:label.id')} value={apiKey.id}/>
                        <SettingsRowValue label={t('apiKeys:label.secret')}
                            value={apiKeySecret == null ?
                                <Label value={t('apiKeys:message.secretAvailableAtCreation')} isItalic/>
                                :
                                <CopySecretLabel secret={apiKeySecret} isDisabled={isDisabled}
                                    copyButtons={(
                                        <>
                                            <Button name="copyIdButton" label={t('apiKeys:option.copyId')}
                                                onClick={onCopyClick} isDisabled={isDisabled}/>

                                            <Button name="copySecretButton" label={t('apiKeys:option.copySecret')}
                                                onClick={onCopyClick} isDisabled={isDisabled}/>
                                        </>
                                    )}
                                />
                            }/>

                        <SettingsRowSeparator/>
                        <SettingsRowValue label={t('apiKeys:label.createdDay')} value={getLocaleDateFromUTC(apiKey.createdDay)}/>
                        <SettingsRowValue label={t('apiKeys:label.validityDays')} value={apiKey.validityDays}/>
                        <SettingsRowValue label={t('apiKeys:label.lastUsed')} value={apiKey.lastUsedDay ? getLocaleDateFromUTC(apiKey.lastUsedDay) : 'N/A'}/>
                    </div>
                </div>
            }
        />
    )
}

export default ApiKeysView;