import React from "react";
import "./View.css";
import CustomModal from "../CustomModal/CustomModal";
import {useTranslation} from "react-i18next";
import {MenuDropdown} from "../Dropdown/Dropdown";
import {buildClassName, isNotEmptyNorFalsy} from "../../../helpers/functions";

function View(props) {
    const {header, body, width, onClose, bodyStyle, isDisabled, ...attr} = props;
    const _bodyStyle = {
        width,
        ...bodyStyle
    };

    return (
        <CustomModal id={"view"} isActive onCancel={onClose} isDisabled={isDisabled} isRigid={isDisabled} {...attr}
            header={
                <div className="view-header" style={width ? {width: `calc(${width} - 1.5em + 0.75em + 2px - 1.5em)`} : {}}>
                    {header}
                </div>
            }
            body={
                <div className="view-body" style={_bodyStyle}>
                    {body}
                </div>
            }
            footer={
                <span className="view-footer"/>
            }
        />
    )
}

export function ViewHeader(props) {
    const {t} = useTranslation(['aria', 'common']);

    const {
        id,
        name,
        enabled=true,
        type,
        typeIcon,
        canModify,
        menuId,
        menuOptions,
        menuOptionHandler,
        isDisabled
    } = props;

    const title = enabled ? name : `${name} (${t('common:label.inactive')})`;

    return (
        <>
            <div className="view-header-left">
                <ObjectIdSubtitle id={id} subtitle={title} isDisabled={isDisabled}/>

                {canModify && isNotEmptyNorFalsy(menuOptions) &&
                    <div className="display-menu">
                        <MenuDropdown id={`${menuId}MenuDropdown`} title={t(`aria:dropdown.${menuId}Menu`)}
                            menuOptions={menuOptions} onOptionClick={menuOptionHandler} isDisabled={isDisabled}/>
                    </div>
                }
            </div>

            <IconSubtitle icon={typeIcon} subtitle={type} isDisabled={isDisabled}/>
        </>
    )
}

export function ObjectIdSubtitle(props) {
    const {
        id,
        subtitle,
        isDisabled,
        ...attr
    } = props;

    // const {showObjectIds} = useSelector(state => state.settingsMap.get(settings.TROUBLESHOOT));
    const showObjectIds = false;
    const className = buildClassName(
        'object-id-subtitle',
        isDisabled && 'is-disabled'
    );

    return (
        <div className={className} {...attr}>
            <h1 className="subtitle is-bold" title={subtitle}>
                {subtitle}
            </h1>
            {showObjectIds &&
                <label className="id-label">
                    {id}
                </label>
            }
        </div>
    )
}

export function IconSubtitle(props) {
    const {
        icon,
        subtitle,

        isDisabled,
        ...attr
    } = props;

    const className = buildClassName(
        'icon-subtitle',
        isDisabled && 'is-disabled'
    );

    return (
        <div className={className} {...attr}>
            {subtitle &&
                <h1 className="subtitle is-bold" style={{marginRight: '0.5rem'}}>
                    {subtitle}
                </h1>
            }
            <span className="icon is-medium">
                <img src={icon} alt={subtitle || ''}/>
            </span>
        </div>
    )
}

export default View;