import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Dropdown.css';
import {
    checkNestedExists,
    convertPixelsToRem,
    convertRemToPixels,
    getReactElementTextContent,
    isNotEmptyNorFalsy
} from '../../../helpers/functions';

import {CheckedSelectableItem, NameSelectableItem} from "../SelectableItem/SelectableItem";
import {useTranslation} from "react-i18next";
import SearchBar from "../SearchBar/SearchBar";
import {buildFakeEvent} from "./helpers";
import arrowDownIcon from "../../../resources/images/icons/arrowDown.svg";


function Dropdown(props) {
    const {t} = useTranslation(['aria']);

    const [isOpen, setIsOpen] = useState(false);
    const [_dropdownStyle, setDropdownStyle] = useState({});
    const [_contentStyle, setContentStyle] = useState({});
    const [_fillerStyle, setFillerStyle] = useState({});

    const dropdownRef = useRef();
    const buttonRef = useRef();

    const {
        id,
        title,
        name,
        icon,
        items,
        keepOpen,
        buttonStyle,
        style,
        searchKey,

        isIconDropdown,
        isBold,
        isRight,
        isUp,
        isOutlined,
        isRequired,
        isInvalid,
        isDisabled
    } = props;


    const getDropdownStyles = useCallback(({minWidth=0, minHeight=0}={}) => {
        const {top, left, bottom, height} = dropdownRef.current.getBoundingClientRect();
        const {width} = buttonRef.current.getBoundingClientRect();
        const offsetParent = dropdownRef.current.offsetParent;

        const {top: parentTop, left: parentLeft} = offsetParent.getBoundingClientRect();
        const offsetParentComputedStyle = window.getComputedStyle(offsetParent);

        const offsetTopBorderWidth = offsetParentComputedStyle.getPropertyValue('border-top-width');
        const offsetLeftBorderWidth = offsetParentComputedStyle.getPropertyValue('border-left-width');

        const newWidth = Math.max(width, minWidth);
        const newHeight = Math.max(height, minHeight);

        // Set style to absolute and position accordingly so dropdown can hover over parent containers if needed
        const dropdownStyle = {
            position: 'absolute',
            top: `calc(${top - parentTop}px - ${offsetTopBorderWidth})`,
            left: `calc(${left - parentLeft}px - ${offsetLeftBorderWidth})`,
            width: `${newWidth}px`
        };

        // Set filler dimensions to give weight in place of the absolute positioned dropdown
        // Because absolute positioning removes the dropdown from DOM flow
        const fillerStyle = {
            width: `${newWidth}px`,
            height: `${newHeight}px`
        };

        // Calculate the content max-height to not overflow browser window
        const bottomPadding = 1;
        const allowedMenuHeight = convertPixelsToRem(window.innerHeight - bottom) - bottomPadding;
        const contentStyle = {
            minWidth: `${Math.max(newWidth, 175)}px`,
            maxHeight: `${allowedMenuHeight}rem`
        };

        return {
            dropdownStyle,
            fillerStyle,
            contentStyle
        }
    }, []);

    const toggleDropdown = useCallback(() => {
        const open = dropdownRef.current && dropdownRef.current.classList.contains('is-open');
        let styles = {};

        if (!open) {
            styles = getDropdownStyles();
        }

        // Set dropdown styles
        const {dropdownStyle, fillerStyle, contentStyle} = styles;
        setDropdownStyle(dropdownStyle);
        setFillerStyle(fillerStyle);
        setContentStyle(contentStyle);

        // toggle isOpen and reset searchText
        setIsOpen(prevIsOpen => !prevIsOpen);
        setSearchText('');

    }, [getDropdownStyles]);

    const closeOnOutsideEvent = useCallback(event => {
        const open = dropdownRef.current && dropdownRef.current.classList.contains('is-open');
        if (open && !dropdownRef.current.contains(event.target)) {
            toggleDropdown();
        }
    }, [toggleDropdown]);

    const closeOnResizeEvent = useCallback(() => {
        const open = dropdownRef.current && dropdownRef.current.classList.contains('is-open');
        if (open) {
            toggleDropdown();
        }
    }, [toggleDropdown]);


    ///////////////////////////////////////////////// SEARCH BAR ///////////////////////////////////////////////////////
    const searchBarRef = useRef();
    const [_searchText, setSearchText] = useState("");
    function searchHandler(event) {
        setSearchText(event.target.value);
    }

    const searchTextEq0 = _searchText.length === 0;
    const showSearchInput = useCallback(event => {
        const {key} = event;
        // No search for iconDropdowns
        if (isIconDropdown || dropdownRef.current == null)
            return;

        const open = dropdownRef.current.classList.contains('is-open');
        if (open && searchTextEq0) {
            // Update styles to have minWidth: 9rem
            const {dropdownStyle, fillerStyle} = getDropdownStyles({minWidth: convertRemToPixels(9)});
            setDropdownStyle(dropdownStyle);
            setFillerStyle(fillerStyle);

            // Set starting searchText
            setSearchText(key);
        }
    }, [isIconDropdown, searchTextEq0, getDropdownStyles]);

    useEffect(() => {
        // Focus on searchBar
        if (isOpen && !searchTextEq0) {
            searchBarRef.current.querySelector('input').focus();
        }

    }, [isOpen, searchTextEq0]);

    // Set dropdown eventListeners
    useEffect(() => {
        document.body.addEventListener('keypress', showSearchInput);
        document.body.addEventListener('mousedown', closeOnOutsideEvent);
        document.body.addEventListener('scroll', closeOnOutsideEvent, true);
        window.addEventListener('resize', closeOnResizeEvent);

        return () => {
            document.body.removeEventListener('keypress', showSearchInput);
            document.body.removeEventListener('mousedown', closeOnOutsideEvent);
            document.body.removeEventListener('scroll', closeOnOutsideEvent, true);
            window.removeEventListener('resize', closeOnResizeEvent);
        }
    }, [closeOnOutsideEvent, closeOnResizeEvent, showSearchInput]);

    const {selectedItems} = props;
    // If selectedItems is larger than current filler width (when dropdown isOpen)
    // Expand filler width
    useEffect(() => {
        if (isOpen && buttonRef.current != null) {
            const {width} = buttonRef.current.getBoundingClientRect();

            setFillerStyle(prevStyle => ({
                ...prevStyle,
                width: `${width}px`
            }));
            setContentStyle(prevStyle => ({
                ...prevStyle,
                minWidth: `${Math.max(width, 175)}px`,
            }));
        }
    }, [isOpen, selectedItems, searchTextEq0]);



    const image = isIconDropdown ? ' is-img' : '';
    const open = isOpen ? ' is-open' : '';
    const bold = isBold ? ' is-bold' : '';
    const right = isRight ? ' is-right' : '';
    const up = isUp ? ' is-up' : '';
    const required = isRequired ? ' is-required' : '';
    const invalid = isInvalid ? ' is-invalid' : '';
    const disabled = isDisabled ? ' is-disabled' : '';
    const outlined = (isOutlined || isOpen) ? '' : ' is-white';


    // Items will either be an Array or a React.Fragment with an Array of children
    let dropdownItems;
    if (Array.isArray(items)) {
        dropdownItems = items;
    } else if (checkNestedExists(items, 'props', 'children') && items.type === React.Fragment) {
        dropdownItems = items.props.children.flat();
    } else {
        dropdownItems = [items];
    }

    const filteredItems = isNotEmptyNorFalsy(dropdownItems) && dropdownItems
        .filter(item => {
            const textContent = getReactElementTextContent(item, searchKey).toLowerCase();

            return textContent.includes(_searchText.toLowerCase());
        });

    return (
        <>
            <div style={_fillerStyle}/>
            <div id={id} className={'_dropdown' + open + right + up} ref={dropdownRef} style={{...style, ..._dropdownStyle}}>

                {_searchText ?
                <SearchBar searchBarRef={searchBarRef} value={_searchText} onChange={searchHandler}/>
                :
                <button type="button" title={title} className={'button is-small' + image + outlined + required + invalid}
                    style={buttonStyle} ref={buttonRef} onClick={toggleDropdown} disabled={isDisabled}
                >

                        {icon &&
                        <span className="icon">
                            {icon}
                        </span>
                        }

                        {!isIconDropdown &&
                        <>
                            <label className={'label is-ellipsis' + bold + disabled}>
                                {selectedItems}
                            </label>
                            <span className="icon is-small">
                                <img src={arrowDownIcon} width={11} className={disabled} alt={t('aria:image.arrowDown')}/>
                            </span>
                        </>
                        }
                    </button>
                }

                {isNotEmptyNorFalsy(filteredItems) &&
                <div className="_dropdown-menu">
                    <div className="_dropdown-content" data-name={name} onClick={keepOpen ? null : toggleDropdown} style={_contentStyle}>

                        {filteredItems}
                    </div>
                </div>
                }
            </div>
        </>
    )
}

export function ListDropdown(props) {
    const {ItemComponent=NameSelectableItem, componentProps, autoSelect,
        noneSelectedMessage, value, items, onItemSelect, isRequired, ...rest} = props;

    let selectedItem = null;
    if (value !== undefined) {
        selectedItem = items.find(_item => _item.key === value || _item.value === value);
    }

    // If only 1 value && value not included in values, select first value
    useEffect(() => {
        if (!autoSelect || selectedItem != null) return;

        let val;
        if (items.length === 1 && items[0] != null) {
            val = items[0].value
        }
        if (val === undefined) {
            val = null;
        }

        if (value !== val) {
            onItemSelect(buildFakeEvent({name: rest.name, value: val}));
        }
    }, [selectedItem, autoSelect, rest.name, value, items, onItemSelect]);

    const selectedName = (selectedItem != null && selectedItem.name) || value || noneSelectedMessage;

    return (
        <Dropdown isBold isRequired={isRequired && !value}
            selectedItems={selectedName} {...rest}
            items={items.map((item, index) =>

                <ItemComponent key={index} item={item} onItemClick={onItemSelect}
                    isNoWrap isDisabled={rest.isDisabled} {...componentProps}
                />
            )}
        />
    )
}

export function CheckedDropdown(props) {
    const {t} = useTranslation(['common']);
    const {
        onToggle,
        onClear,
        setSelected,
        items,
        ItemComponent=CheckedSelectableItem,
        noneSelectedMessage,
        ...attr
    } = props;

    function onItemClick(event) {
        if (typeof onToggle === 'function') {
            onToggle(event);
        }

        if (typeof setSelected === 'function') {
            const {value} = event.currentTarget.dataset;
            setSelected(prevSelected => ({
                ...prevSelected,
                [value]: !prevSelected[value]
            }));
        }
    }

    function onClearSelected(event) {
        if (typeof onClear === 'function') {
            onClear(event);
        }

        if (typeof setSelected === 'function') {
            setSelected({});
        }
    }

    let selectedItems = noneSelectedMessage;
    const someChecked = items.some(item => item.isChecked);

    if (someChecked) {
        selectedItems = items
            .filter(item => item.isChecked)
            .map(item => item.name)
            .join(', ');
    }

    return (
        <Dropdown keepOpen selectedItems={selectedItems} {...attr}
            items={items.length > 0 &&
            <>
                <NameSelectableItem item={{name: t('common:option.clearSelected')}} onItemClick={onClearSelected}
                    isNoWrap isCentered isBorder isDisabled={attr.isDisabled || !someChecked}
                />
                {items.map(item =>
                    <ItemComponent key={item.key || item.value} item={item} onItemClick={onItemClick}
                        isEllipsis isNoWrap isDisabled={attr.isDisabled}
                    />
                )}
            </>
            }
        />
    )
}

export function MenuDropdown(props) {
    const {menuOptions, onOptionClick, isDisabled, ...attr} = props;
    const {t} = useTranslation(['aria']);

    return (
        <Dropdown isIconDropdown isDisabled={isDisabled} {...attr}
            icon={
                <img src={arrowDownIcon} width={14} alt={t('aria:image.arrowDown')}/>
            }
            items={
                menuOptions.map((option, index) => {
                    const {name, value, isSeparator} = option;

                    return isSeparator ?
                        <span key={index} className="dropdown-menu-separator"/>
                        :
                        <NameSelectableItem key={value} onItemClick={onOptionClick} item={{name, value}}
                            isNoWrap isDisabled={isDisabled || option.isDisabled}
                        />
                })
            }
        />
    );
}

// export function LocaleDropdown(props) {
//     const {selectedLocale = localeKeys.BROWSER_DEFAULT, onLocaleSelect, ...rest} = props;
//     const {t} = useTranslation(['aria', 'uiLanguage']);
//
//     const {BROWSER_DEFAULT, ...otherLocaleKeys} = localeKeys;
//     const localeItems = [
//         {
//             name: t(`uiLanguage:locale.${BROWSER_DEFAULT}`, {
//                 lng: browserDefaultLanguage,
//                 language: browserDefaultLanguage
//             }),
//             value: BROWSER_DEFAULT
//         },
//         ...getValues(otherLocaleKeys)
//             .map(_locale => ({
//                 name: t(`uiLanguage:locale.${_locale}`, {lng: _locale}),
//                 value: _locale
//             }))
//     ];
//
//     return (
//         <ListDropdown id={'localeDropdown'} name={'locale'}
//             title={t('aria:dropdown.locale')} value={selectedLocale}
//             items={localeItems} onItemSelect={onLocaleSelect}
//             buttonStyle={{maxWidth: '20rem', justifyContent: 'flex-start'}}
//             {...rest}
//         />
//     )
// }

export default Dropdown;