import ReduxModel from "../ReduxModel";
import axiosProxy from "../../../axios/AxiosProxy";
import ComponentStateModel from "../ComponentStateModel";
import {RAMPIVA_PORTAL_SHOW_LOGIN_BANNER} from "../../../helpers/localStorage";
import {stringToBool} from "../../../helpers/functions";


class CurrentUserModel extends ReduxModel {

    static nom = 'CurrentUserModel';
    static actions = CurrentUserModel.buildActions();
    static actionCreators = CurrentUserModel.buildActionCreators(CurrentUserModel.actions);
    static reducer = CurrentUserModel.buildReducer(CurrentUserModel.actions);

    static componentActionCreators = CurrentUserModel.buildComponentActionCreators();

    constructor(model) {
        super();
        if (model != null) {
            this.name = model.name;
            this.organizationId = model.organizationId;
            this.isAuthenticated = !!model.isAuthenticated;
        }
    }

    static buildActions() {
        return {
            INITIALIZE_USER: 'INITIALIZE_USER',
            CLOSE_USER_ACCOUNT: 'CLOSE_USER_ACCOUNT',

            SET_USER_DATA: 'SET_USER_DATA',
            SET_CURRENT_USER: 'SET_CURRENT_USER',
            FINISHED_SETTING_CURRENT_USER: 'FINISHED_SETTING_CURRENT_USER',
            UPDATE_ORGANIZATION_ID: 'UPDATE_CURRENT_USER_ORGANIZATION_ID',

            RESET_IDLE_TIMER: 'RESET_IDLE_TIMER',
            LOGOUT_USER: 'LOGOUT_USER',
            CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER'
        }
    }

    static buildActionCreators(actions) {
        return {
            initializeUser: this.actionCreator(actions.INITIALIZE_USER),
            closeUserAccount: this.actionCreator(actions.CLOSE_USER_ACCOUNT),

            setUserData: this.actionCreator(actions.SET_USER_DATA, 'userData'),
            setCurrentUser: this.actionCreator(actions.SET_CURRENT_USER, 'userData'),
            finishedSettingCurrentUser: this.actionCreator(actions.FINISHED_SETTING_CURRENT_USER),
            updateOrganizationId: this.actionCreator(actions.UPDATE_ORGANIZATION_ID, 'organizationId'),

            resetIdleTimer: this.actionCreator(actions.RESET_IDLE_TIMER),
            logoutUser: this.actionCreator(actions.LOGOUT_USER),
            clearCurrentUser: this.actionCreator(actions.CLEAR_CURRENT_USER)
        }
    }

    static buildComponentActionCreators() {
        const showLoginPageBanner = localStorage.getItem(RAMPIVA_PORTAL_SHOW_LOGIN_BANNER);

        const components = [
            {
                key: 'loginPage',
                type: 'LoginPage',
                state: {
                    showBanner: showLoginPageBanner == null || stringToBool(showLoginPageBanner),
                    isDisabled: false
                }
            },
            {
                key: 'registrationPage',
                type: 'RegistrationPage',
                state: {
                    organizationName: '',
                    captchaValue: null,
                    keepActive: false,
                    isDisabled: false
                }
            }, {
                key: 'resourcesPage',
                type: 'ResourcesPage',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new this(), action) {
            switch (action.type) {
                case actions.SET_CURRENT_USER: {
                    const {userData: {username, organizationId}} = action.payload;

                    return new this({
                        name: username,
                        organizationId,
                        isAuthenticated: true
                    });
                }
                case actions.UPDATE_ORGANIZATION_ID: {
                    const {organizationId} = action.payload;

                    return state.duplicate({organizationId});
                }
                case actions.CLEAR_CURRENT_USER: {
                    return new this();
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class CurrentUserApi {

    static getUser() {
        return axiosProxy.get('/user');
    }

    static refreshToken() {
        return axiosProxy.get(`/user/refresh`);
    }

    static postOidcNonce(nonce) {
        return axiosProxy.post('/user/external/oidcToken', {state: nonce});
    }

    static postUserInitialize(initInfo) {
        return axiosProxy.post('user/external/initialize', initInfo);
    }

    static deleteUser() {
        return axiosProxy.del('/user/external');
    }

    static deleteLogin() {
        return axiosProxy.del('/user');
    }
}

export default CurrentUserModel;