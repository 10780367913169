import React, {useCallback, useEffect} from "react";
import './LicensePage.css';
import Label, {CopySecretLabel} from "../../common/Label/Label";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import LicensesModel, {LicensesApi} from "../../../redux/models/home/LicensesModel";
import {PageHeader} from "../../common/PanelPage/TabbedPage";
import {SettingsRowSeparator, SettingsRowValue} from "../../common/Table/Table";
import {getLocaleDateTimeFromUTC} from "../../../helpers/functions";
import AppModel from "../../../redux/models/app/AppModel";
import axiosProxy, {devUrl} from "../../../axios/AxiosProxy";

export function useGetLicenseKey(license) {
    const dispatch = useDispatch();

    return useCallback(async () => {
        if (license.organizationId != null && license.id != null) {
            const res = await LicensesApi.getLicenseCredentials(license.organizationId, license.id)
                .catch(error => dispatch(AppModel.actionCreators.handleError(error)));

            if (res.data != null) {
                return res.data.licenseKey;
            }
        }
    }, [license.organizationId, license.id, dispatch]);
}

function LicensesPage() {
    const {t} = useTranslation(['licenses']);
    const dispatch = useDispatch();


    const licenses = useSelector(state => state.licenses);
    useEffect(() => {
        dispatch(LicensesModel.componentActionCreators.setDisplayActive());
        return () => dispatch(LicensesModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);

    return (
        <>
            <PageHeader title={t('licenses:label.name')}/>

            {licenses.map(license =>
                <LicenseView key={license.id} license={license}/>
            )}
        </>
    )
}

function LicenseView(props) {
    const {t} = useTranslation(['licenses']);

    const {
        license
    } = props;
    // Query for credentials
    const getLicenseKey = useGetLicenseKey(license);

    return (
        <div className="license-view">
            <Label value={license.name} isHeading/>
            <Label value={license.description}/>

            <div className="settings-table display-input">
                <div className="table-row-group">
                    <SettingsRowValue label={t('licenses:label.id')} value={license.id}/>
                    <SettingsRowValue label={t('licenses:label.key')}
                        value={(
                            <CopySecretLabel getSecret={getLicenseKey} copyTitle={t('licenses:option.copyLicense')}
                                copyObject={{licenseId: axiosProxy.baseUrl === devUrl ? `dev.api.rampiva.services:${license.id}` : license.id}}/>
                        )}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('licenses:label.environment')} value={t(`licenses:environment.${license.environment}`)}/>
                    <SettingsRowValue label={t('licenses:label.edition')} value={t(`licenses:edition.${license.edition}`)}/>
                    <SettingsRowValue label={t('licenses:label.module')} value={Array.isArray(license.modules) &&
                        license.modules.map(module => t(`licenses:module.${module}`)).join(', ')}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('licenses:label.startDate')} value={getLocaleDateTimeFromUTC(license.startDate)}/>
                    <SettingsRowValue label={t('licenses:label.endDate')} value={getLocaleDateTimeFromUTC(license.endDate)}/>
                </div>
            </div>
        </div>
    )
}

export default LicensesPage;