import axiosProxy from "../axios/AxiosProxy";

export const RAMPIVA_PORTAL_SHOW_LOGIN_BANNER = "rampivaPortalShowLoginBanner";
const RAMPIVA_USERNAME = "rampivaPortalUsername";
const RAMPIVA_USER_TOKEN = "rampivaPortalUserToken";
const RAMPIVA_OIDC_NONCE = "rampivaPortalOidcLoginNonce";

export const setAzureNonce = nonce => {
	localStorage.setItem(RAMPIVA_OIDC_NONCE, nonce);
};

export const getAzureNonce = () => {
	const nonce = localStorage.getItem(RAMPIVA_OIDC_NONCE);
	localStorage.removeItem(RAMPIVA_OIDC_NONCE);
	return nonce;
};

export const clearUserData = () => {
    // Only remove localStorage items if clearId matches
	if (axiosProxy.token === localStorage.getItem(RAMPIVA_USER_TOKEN)) {
		localStorage.removeItem(RAMPIVA_USERNAME);
		localStorage.removeItem(RAMPIVA_USER_TOKEN);
	}
	axiosProxy.clearAxiosToken();
};

export function getLocalStorageSubsetWithPrefix(prefix) {
	const subset = {};

	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (key.match(`^${prefix}`)) {

			subset[key] = JSON.parse(window.localStorage.getItem(key));
		}
	}
	return subset;
}